@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Light.eot');
  src: url('Metropolis-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Metropolis-Light.woff2') format('woff2'),
  url('../fonts/Metropolis-Light.woff') format('woff'),
  url('../fonts/Metropolis-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Medium.eot');
  src: url('../fonts/Metropolis-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Metropolis-Medium.woff2') format('woff2'),
  url('../fonts/Metropolis-Medium.woff') format('woff'),
  url('../fonts/Metropolis-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Bold.eot');
  src: url('../fonts/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Metropolis-Bold.woff2') format('woff2'),
  url('../fonts/Metropolis-Bold.woff') format('woff'),
  url('../fonts/Metropolis-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

$transition-time: 0.2s;

/** {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}*/

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  padding: 0;
  margin: 0;
  font-family: 'Metropolis', sans-serif;
  font-size: 16px;
  color: #94989b;
  font-weight: 300;
  overflow-x: hidden;
  zoom: 1;
}

#preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  .progress {
    position: relative;

    .progress-bar {
      background: #0a4c98;
      height: 3px;
      transition: 0.1s;
    }
  }
}

#page {
  width: 1362px;
  opacity: 0;
  margin: auto;
  padding-left: 81px;
  box-sizing: border-box;
  position: relative;
  margin-top: 81px;

  @media (max-width: 1366px) {
    width: auto;
    margin: 0;
    margin-top: 81px;
  }

  @media (max-width: 980px) {
    padding-left: 31px;
  }
}


body.loaded {
  #page {
    opacity: 1;
  }

  #preloader {
    display: none;
  }
}

nav {
  padding-top: 28px;
  margin-bottom: 14px;
  user-select: none;
  display: flex;
  transition: padding $transition-time;
}

.prefoot {
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;

  ul, li {
    list-style-type: none !important;
  }

  @media (max-width: 1170px) {
    .separate-mobile {
      display: block;
      height: 20px;
      flex-basis: 100%;
    }
  }

  .foot-row {
    flex: 1 1 20%;

    ul {
      padding: 0;
      padding-top: 2px;
      margin: 0;
      margin-top: 15px;
      margin-left: 0;

      li {
        margin-bottom: 13px;
        margin-left: 0;

        &.last {
          margin-top: 21px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    a {
      text-decoration: none;
      color: #58626c;
      user-select: none;
      transition: color 0.2s;
      font-size: 15px;

      &:hover {
        color: #0a4c98;
        transition: color 0.2s;
      }

      &.link-title {
        color: #0a4c98;
        font-size: 19px;
        border-bottom: 1px solid #e8edf3;
      }
    }

    b {
      border-bottom: 1px solid #b2bcd5;
      font-size: 18px;
      color: #0a4c98;
    }

    &:last-of-type {
      flex: 0 1 18px;
    }

    @media (max-width: 1170px) {
      flex: 0 1 49%;

      &:last-of-type {
        display: none;
      }
    }

    @media (max-width: 480px) {
      flex: 0 1 100%;
    }

    &.margined {
      @media (max-width: 1170px) {
        margin-bottom: 30px;
      }
    }

    &.margined-mobile {
      @media (max-width: 480px) {
        margin-top: 35px;
      }
    }
  }
}

footer {
  border-top: 1px solid #e8edf3;
  padding-top: 57px;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 56px;
  margin-top: 59px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .footer-text {
    padding-right: 10px;
    margin-bottom: 5px;
  }

  .vilsone-logo {
    img {
      border: 0;
      vertical-align: middle;
      display: inline-block;
      margin-left: 5px;
    }
  }

  @media (max-width: 480px) {
    .vilsone-logo {
      float: none;
      display: block;
      margin-bottom: 5px;
    }
  }
}

.selectable {
  appearance: none;
  border-radius: 0;
  border: 1px solid #e8edf3;
  display: inline-block;
  cursor: pointer;
  outline: none;
  padding: 7px 12px;
  color: #55626b;

  &:focus,
  &:hover {
    outline: none;
  }

  &.error {
    border-color: #08478c;
    animation: hideErrorEffect 1s 0.2s;
    animation-fill-mode: forwards;
  }
}

.logotype {
  float: left;
  position: relative;
  top: -10px;

  img {
    border: 0;
    height: 52px;
  }

  @media (max-width: 480px) {
    img {
      width: auto;
      height: 52px;
      margin-top: 3px;
    }
  }
}

aside {
  float: right;
  display: block;
  right: 0;
  top: 0;
  width: 81px;
  transition: width 0.4s, opacity 0.1s;
  position: fixed;
  z-index: 100;
  height: auto;
  opacity: 0;

  &.loaded {
    opacity: 1;
    transition: opacity 0.1s;
  }

  ul {
    display: block;
    margin: auto;
    width: 18px;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    padding-top: 56px;
  }

  @media (min-width: 1365px) {
    right: auto;
    position: relative;
  }

  @media (max-width: 1000px) {
    height: 80px;
  }
}


.socials {
  display: block;
  margin: auto;
  width: 18px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding-top: 56px;
  list-style-type: none;

  @media (max-width: 980px) {
    display: none;
  }

  li {
    text-align: center;
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 22px;
    padding-top: 24px;
    width: 18px;

    a {
      color: #99a0a8;
      transition: color 0.2s;
    }

    &:hover {
      a {
        color: #0b4790;
        transition: color 0.2s;
      }
    }

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }

    &:first-of-type {
      padding-top: 0;
    }
  }
}

#cookiebox {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f0f4f7;
  display: none;
  z-index: 105;

  &.visible {
    display: block;
  }

  .cookiebox__content {
    display: flex;
    margin: auto auto;
    box-sizing: border-box;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    padding: 14px 30px 14px 30px;

    svg {
      display: inline-block;
      margin-right: 10px;
    }

    a {
      color: #0a4c98;
      text-decoration: none;
      border-bottom: 1px solid #e8edf3;
    }

    button {
      appearance: none;
      border: 0;
      background: #08478c;
      color: #fff;
      outline: none;
      padding: 8px 17px;
      text-transform: uppercase;
      position: relative;
      cursor: pointer;
      margin-left: 15px;

      &:hover {
        background: #2ecbea
      }
    }
  }
}

.more-button {
  transform: translateX(0);
  transition: transform 0.3s;

  &:hover {
    transform: translateX(5px);
    transition: transform 0.3s;
  }
}

#slider-default {
  video {
    max-width: 100%;
    width: 100%;

    @media (max-width: 865px) {
      max-width: none;
      width: auto;
      height: 450px;
    }
  }
}

.menu-opened {
  aside {
    .navigation-mobile {
      top: 0;
      transition: top 0.3s ease-in;
    }

    #menu {
      background: #08478c;

      div {
        span {
          background: #fff;

          &:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
          }

          &:nth-child(2) {
            transform: rotate(45deg);
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
          }

          &:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
          }
        }
      }
    }

    .socials {
      margin: 0;
      width: auto;
      display: flex;
      list-style-type: none;

      li {
        flex: 1 1 25%;
        display: inline-block;
        border-bottom: 0;
        border-right: 1px solid #d2d2d2;
        padding: 0;

        &:last-of-type {
          border-right: 0;
        }

        &:first-of-type {
          padding-left: 0;
        }
      }
    }
  }
}

.navigation-mobile {
  position: fixed;
  user-select: none;
  left: 0;
  right: 0;
  background: #08478c;
  height: 100vh;
  z-index: 105;
  display: block;
  width: 100%;
  top: 150%;
  transition: top 0.3s ease-out;
  padding: 100px 30px 0 30px;

  .navigation-mobile-langs {
    display: none;

    @media (max-width: 412px) {
      display: flex;
      position: absolute;
      top: 30px;
      left: 63px;

      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-right: 15px;
      }
    }
  }

  li {
    display: block;
    margin-bottom: 10px;
    border-bottom: 1px solid #32659e;
    padding-bottom: 12px;
    padding-left: 35px;
    padding-top: 3px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;


    a {
      color: #fff;
      text-decoration: none;
      transition: all 0.2s;
      font-size: 20px;
      -webkit-tap-highlight-color: transparent;
    }

    &:hover {
      a {
        color: #519bec;
        transition: all 0.2s;
      }
    }

    &:last-of-type {
      margin-right: 0;
      border-bottom: 0;
    }
  }

  @media (min-width: 1300px) {
    display: none;
  }
}

.fontawesome-i2svg-complete {
  .rowbox {
    .heading-image {
      .icon {
        opacity: 1;
        transition: opacity 0.2s;
      }
    }
  }
}

.container {
  display: block;
  margin-right: 81px;
  clear: both;
  max-width: 1200px;

  @media (max-width: 980px) {
    margin-right: 31px;
  }
}

.navigation {
  list-style-type: none;
  float: right;
  margin-top: 9px;

  li {
    display: inline-block;
    margin-right: 40px;
    position: relative;
    height: 47px;
    font-weight: 500;

    .navigation-more {
      display: none;
      width: auto;
      position: absolute;
      top: 100%;
      background: rgba(12,54,139, 0.9);
      left: 0;
      margin-left: -14px;

      a {
        white-space: nowrap;
        display: inline-block;
        color: #a5cbf5;
        border-bottom: 1px solid #0072e8;
        font-size: 14px;
        transition: color 0.2s;
        cursor: pointer;
        width: 100%;

        span {
          color: #a5cbf5;
          transition: color 0.2s;
          height: auto;
          padding-top: 0;
          top: auto;
          margin: 13px 40px;
          display: block;
        }

        &:first-of-type {
          padding-top: 4px;
        }

        &:last-of-type {
          border: 0;
          /*padding-bottom: 22px;*/
        }

        &:hover {
          color: #fff;
          transition: color 0.2s;
        }

        &:hover {
          a, span {
            color: #fff;
            transition: color 0.2s;
          }
        }
      }
    }

    a {
      color: #58626c;
      text-decoration: none;
      transition: color 0.2s;
      font-size: 15px;
      display: inline-block;

      &.main-nav {
        display: block;
        width: 100%;
        height: 100%;
        padding: 20px 0 47px 0;
        position: relative;
        top: -20px;
      }
    }

    &.hoverable {
      &:hover {
        &:before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 7.5px 10px 7.5px;
          border-color: transparent transparent #08478c transparent;
          position: absolute;
          top: 100%;
          display: block;
          left: 30px;
          transform: translateX(-50%);
          margin-top: -9px;
        }
      }
    }

    &:hover {
      a {
        color: #0b4790;
        transition: color 0.2s;
      }

      .navigation-more {
        display: block;
      }
    }

    &.current {
      a {
        color: #0b4790;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 1366px) {
    margin-right: 20px;

    li {
      margin-right: 20px;
    }
  }

  @media (max-width: 1200px) {
    display: none;
  }
}


.selectable-trigger {
  border: 1px solid #e8edf3;
  box-sizing: border-box;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px 19px 0 19px;
  margin-left: 20px;
  position: relative;
  font-size: 14px;
  height: 34px;
  margin-top: -1px;
  white-space: nowrap;

  svg {
    color: #08478c;
    display: inline-block;
    margin-left: 5px;

    &.hidden {
      display: none;
    }
  }

  .trigger-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #e8edf3;
    border-top: 0;
    background: #fff;
    z-index: 1;
    margin-left: -1px;
    margin-right: -1px;
    display: none;

    .option {
      padding: 7px 15px;
      border-top: 1px solid #e8edf3;
    }

    img {
      width: 16px;
      border: 0;
      float: right;
    }

    &.opened {
      display: block;
    }
  }

  @media (max-width: 480px) {
    margin-top: 2px;
  }

  @media (max-width: 412px) {
    display: none;
  }
}

.button {
  appearance: none;
  cursor: pointer;
  border-radius: 0;
  outline: none;
  display: inline-block;
  user-select: none;
  text-transform: uppercase;

  &:focus,
  &:hover {
    outline: none;
  }

  &.loading {
    animation: flickerButton 1s infinite alternate;
    pointer-events: none;
    @keyframes flickerButton {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0.8;
      }
    }
  }

  &.default {
    background-color: #08478c;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    height: 49px;
    box-sizing: border-box;
    padding: 0 34px 0 64px;
    position: relative;
    border: 0;

    &.product-ask {
      margin-top: 20px;
    }

    &.transparent {
      background-color: transparent;

      span {
        color: #2ecbea;
      }
    }

    &.banner {
      border: 1px solid #2ecbea;
      background: transparent;
      color: #fff;
    }

    &.margined {
      margin-bottom: 12px;
    }

    span {
      &:before {
        content: "";
        width: 13px;
        height: 2px;
        background-color: #2ecbea;
        display: block;
        position: relative;
        top: 8px;
        left: -33px;
      }
    }

    &.right {
      float: right;

      &:after {
        content: "";
        clear: both;
        display: table;
      }
    }
  }
}

.centered {
  display: flex;
  flex-direction: column;

  a {
    margin: 0 auto;
  }
}

#menu {
  display: none;
  width: 0;
  transition: width $transition-time, height $transition-time;

  @media (max-width: 1200px) {
    display: block;
    background: #fff;
    height: 84px;
    border: 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 106;
    width: 81px;
    padding: 0;

    div {
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      position: relative;
      display: block;
      width: 25px;
      height: 33.33333%;
      margin: auto auto;
      margin-top: 5px;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #08478c;
        border-radius: 0;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2), &:nth-child(3) {
          top: 10px;
        }

        &:nth-child(4) {
          top: 20px;
        }
      }
    }
  }
}

#search {
  background: #08478c;
  display: block;
  color: #fff;
  font-size: 18px;
  border: 0;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 81px;
  height: 84px;
  transition: width $transition-time, height $transition-time;

  a {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 1200px) {
    display: none;
  }

  svg {
    transition: all 0.2s;
  }

  &:hover {
    background: #2ecbea;

    svg {
      transform: scale(1.3);
      transition: all 0.2s;
    }
  }
}


.scrolling {
  #search {
    width: 61px;
    height: 64px;
    transition: width $transition-time, height $transition-time;
  }

  nav {
    padding-top: 15px;
    transition: padding $transition-time;
  }

  .navbar {
    height: 64px;
    transition: height $transition-time;
  }

  .navigation {
    li {
      &.hoverable {
        &:hover {
          &:before {
            margin-top: -16px;
          }
        }
      }

      .navigation-more {
        top: 40px;
      }
    }
  }

  #menu {
    /*width: 61px;*/
    height: 64px;
    transition: width $transition-time, height $transition-time;

    @media (max-width: 1200px) {
      div {
        top: -3px;
      }
    }
  }
}

li,
ul {
  list-style-type: none;
}

.banner {
  height: 305px;
  background-color: #09488d;
  position: relative;
  overflow: hidden;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;

  .description {
    color: #fff;
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 6px;

    h1 {
      font-size: 46px;
      margin: 0;
      letter-spacing: 3px;
      opacity: 0;
      animation: showTitlemain 0.3s;
      animation-fill-mode: forwards;

      @keyframes showTitlemain {
        100% {
          opacity: 1;
        }
      }
    }

    @media (max-width: 980px) {
      left: 31px;
      right: 31px;

      h1 {
        font-size: 30px;
      }
    }

    .breadcrumb {
      display: block;
      font-size: 15px;
      font-weight: 500;
      color: #c3cdd9;
      margin-top: 5px;

      i {
        font-size: 18px;
        position: relative;
        top: 2px;
        display: inline-block;
        margin-right: 3px;

        &:last-of-type {
          display: none;
        }
      }

      a {
        text-decoration: none;
        color: #c3cdd9;
      }
    }
  }

  h3 {
    position: absolute;
    right: 66px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 3px;
    margin-top: 12px;
    user-select: none;
    transition: right 0.3s;

    &:hover {
      right: 61px;
      transition: right 0.3s;
    }

    &:before {
      content: "";
      background-color: #2ec4e9;
      width: 13px;
      height: 1px;
      display: block;
      position: absolute;
      margin-left: -33px;
      top: 6px;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    @media (max-width: 980px) {
      transform: none;
      top: auto;
      right: auto;
      left: 61px;
      bottom: 66px;
    }
  }
}

.slider-smaller {
  .anythingSlider-default {
    .anythingControls ul {
      position: absolute;
      top: 100%;
      display: block;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      right: auto;
      margin-top: 23px;

      a {
        color: #58626c;

        &.cur {
          color: #0a4c98;
          border-bottom: 1px solid #2ecbea;
        }
      }

      li {
        &:last-of-type {
          a {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.anythingSlider-default {
  margin: 0 auto;
  padding: 0;

  &.loaded {
    opacity: 1;

    #slider {
      opacity: 1;
    }
  }

  .arrow {
    display: none !important;
  }

  #slider {
    width: 100%;
    height: 570px;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;

    &.smaller {
      height: 409px;
      margin-bottom: 0;
    }
  }

  #slider-miniature {
    width: 100%;
    height: 570px;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;

    &.smaller {
      height: 409px;
      margin-bottom: 0;
    }
  }

  .anythingControls {
    outline: 0;
    display: none;

    ul {
      margin: 0;
      padding: 0;
      position: absolute;
      right: 30px;
      bottom: 30px;
      user-select: none;

      li {
        display: inline;
        margin: 0 34px 0 0;

        &.last {
          margin-right: 0;
        }
      }

      a {
        display: inline-block;
        text-decoration: none;
        padding: 0;
        height: 18px;
        text-align: center;
        outline: 0;
        font-weight: 600;
        letter-spacing: 1px;
      }
    }

    a {
      background: transparent;
      color: #949494;
      border-radius: 0;
      font-size: 13px;

      span {
        visibility: visible;
      }

      &.cur {
        color: #fff;
      }
    }

    .anythingNavWindow {
      overflow: hidden;
      float: left;
    }
  }
}

.anythingSlider {
  display: block;
  overflow: visible !important;
  position: relative;
  margin-bottom: 70px;

  .anythingWindow {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .anythingBase {
    background: transparent;
    list-style: none;
    position: absolute;
    overflow: visible !important;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }

  .panel {
    background: transparent;
    display: block;
    overflow: hidden;
    float: left;
    padding: 0;
    margin: 0;

    img {
      height: 409px;
    }
  }

  .fade {
    .panel {
      float: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .activePage {
      z-index: 1;
    }
  }
}

.anythingSlider.rtl .anythingWindow {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.anythingSlider,
.anythingSlider .anythingControls ul a,
.anythingSlider .anythingWindow,
.anythingSlider .arrow a,
.anythingSlider .start-stop {
  transition-duration: 0s;
}

h3.page-title {
  font-weight: 500;
  font-style: normal;
  font-size: 38px;
  color: #0a4c98;
  margin-top: 0;

  &.center {
    display: block;
    width: 100%;
    text-align: center;
  }

  &.gray {
    color: #868585;
    font-weight: 500;
    font-size: 36px;
  }

  &.smaller {
    font-size: 33px;
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 50px;

    &.nomargin {
      margin-bottom: 10px;
    }
  }
}

.blue {
  h3.page-title {
    color: #fff;

    @media (max-width: 980px) {
      margin: 0;
      margin-bottom: 41px;

    }
  }
}

.map-container {
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;
  height: 500px;


  #map {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .map-info {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    background: #08478c;
    color: #fff;
    padding: 36px 42px 36px 43px;
    font-size: 17px;
    width: calc(50% - 50px);
    box-sizing: border-box;

    @media (max-width: 1100px) {
      left: 0;
      top: 0;
      bottom: 4px;
      transform: none;
    }

    b {
      display: block;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }

    p {
      margin-top: 10px;
      margin-bottom: 34px;
    }

    a {
      display: block;
      color: #fff;
      text-decoration: none;
      margin-bottom: 10px;

      svg {
        font-size: 19px;
        width: 20px;
        display: inline-block;
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
    }
  }

  @media (max-width: 1050px) {
    height: auto;

    #map {
      position: relative;
      height: 300px;
    }

    .map-info {
      position: relative;
      margin-top: 50px;
      width: 100%;
    }
  }
}

.inline-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 70px;
  align-items: stretch;
  width: 100%;

  &.more-border {
    margin-left: -7px;
    margin-right: -7px;

    .default-icon {
      margin-right: 5px;
    }
  }

  .row {
    flex: auto;
    width: 25%;
    box-sizing: border-box;
    flex-grow: 0;

    &.row3 {
      width: 57%;

      &.nopadding {
        padding-left: 0;
      }

      &.paddingright {
        padding-right: 100px;

        @media (max-width: 980px) {
          padding-right: 0;
        }
      }

      &.paddingleft {
        padding-left: 50px;

        @media (max-width: 980px) {
          padding-left: 0;
        }
      }
    }

    &.rowfour {
      padding: 0 11px;
      margin-bottom: 28px;

      @media (max-width: 1250px) {
        flex: 0 1 33.33333%;
      }

      @media (max-width: 880px) {
        flex: 0 1 50%;
      }

      @media (max-width: 600px) {
        flex: 0 1 100%;
      }

      @media (max-width: 480px) {
        padding: 0;
      }
    }

    &.row2 {
      width: 43%;
      box-sizing: border-box;

      img {
        max-width: 100%;
      }

      &.bigpadding {
        padding-left: 150px;
        padding-right: 0;
      }
    }

    &.rowthree {
      border: 0;
      margin-bottom: 28px;
      box-sizing: border-box;
      padding: 0 11px;
      width: 33.33333%;

      a {
        padding: 7px;
        box-sizing: border-box;
      }

      &.nopadding {
        padding-left: 0;
      }

      &.no-margin-bottom {
        margin-bottom: 0;
      }

      &.margined {
        padding: 0 13px;

        @media (max-width: 480px) {
          padding: 0;
        }
      }

      @media (max-width: 980px) {
        width: 50%;

        &.nopadding {
          padding-bottom: 0;
        }
      }

      @media (max-width: 680px) {
        width: 100%;
        padding: 0;
      }
    }

    &.rowfive {
      flex: 0 1 30%;
      padding-left: 0;

      @media (max-width: 980px) {
        flex: 0 1 100%;
      }
    }

    &.rowhalf {
      padding: 0 13px;
      overflow: hidden;
      margin-bottom: 28px;
      box-sizing: border-box;
      flex: auto;
      width: 50%;

      .rowbox {
        .controll {
          margin: 0 28px 21px;

          a.more-button i {
            top: 13px;
          }
        }
      }

      &.paddingright {
        padding-right: 30px;
      }


      &.nopadding {
        padding-right: 0;
      }

      &.margined {
        padding: 0 13px;

        @media (max-width: 480px) {
          padding: 0;
        }
      }

      @media (max-width: 880px) {
        &.rowhalf {
          width: 100%;
        }
      }

      @media (max-width: 480px) {
        &.rowhalf {
          padding: 0;
        }
      }
    }

    .details {
      display: inline-block;
      margin-left: 9px;
      max-width: 190px;
      line-height: 23px;
      cursor: default;
      user-select: text;

      b {
        display: block;
        color: #0a4c98;
        margin-bottom: 2px;
      }
    }

    svg {
      color: #31caec;
      font-size: 30px;
      vertical-align: top;
    }

    &.blued {
      height: 300px;
      padding: 0 11px !important;
      box-sizing: border-box;
      margin-bottom: 28px;

      @media (max-width: 480px) {
        padding: 0 !important;
      }
    }
  }

  &.responsive-nomargin {
    @media (max-width: 800px) {
      .row {
        padding-bottom: 0;
      }
    }
  }

  &.small-margin {
    margin-bottom: 13px;
  }

  &.half-margin {
    margin-bottom: 30px;
  }

  &.fixmargin {
    margin-left: -13px;
    margin-right: -13px;
    width: auto;
    min-width: 100%;

    @media (max-width: 480px) {
      margin: 0;
    }
  }

  &.fixmargin2 {
    @media (max-width: 680px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.nodecoration {
    a {
      text-decoration: none;
    }
  }

  &.link {
    .quote-image {
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }
  }

  &.listed {
    .listed-icon {
      font-size: 55px;
      margin-bottom: 18px;
    }
  }

  &.table {
    .row {
      padding: 0;
      color: #94989b;
      font-weight: 500;

      &.noborder {
        border: 0;
      }

      &.nomargin {
        margin-bottom: 0;
        margin-right: 0;
      }

      a {
        text-decoration: none;
        color: #0a4c98;
        user-select: none;

        &.download {
          display: block;
          margin: 7px 0 6px;
          font-weight: 500;

          svg {
            display: inline-block;
            margin-right: 11px;
            font-size: 25px;
          }

          .additional {
            color: #58626c;
            display: block;
            font-size: 14px;
            font-weight: 500;
            margin-top: 0;
          }
        }
      }

      .rowbox {
        margin: 0;
        padding: 17px 33px 14px;
        box-sizing: border-box;

        &.nopadding {
          padding: 0;
        }

        &.blued-hover {
          height: 300px;
          padding: 0;
          background-position: center center;
          background-size: auto 100%;
          background-repeat: no-repeat;
          position: relative;

          .bluedbox {
            background-color: rgba(9, 72, 141, 0.9);
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 10px;
            top: auto;

            b {
              color: #fff;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              margin: 10px 14px;
            }

            .button {
              display: none;
            }
          }

          &:hover {
            .bluedbox {
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              text-align: center;

              .box {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              .button {
                display: block;
                margin: 5px auto 0;
                background-color: transparent;
                border: 2px solid #2ecbea;
                white-space: nowrap;
              }
            }
          }
        }
      }

      &.heading {
        background-color: #165eb2;
        color: #fff;
        box-sizing: border-box;
        font-weight: 500;

        .rowbox {
          border-color: #165eb2;
        }

        &:first-of-type {
          background-color: #0a4c96;

          .rowbox {
            border-color: #0a4c96;
          }
        }

        &:last-of-type {
          background-color: #2471c9;

          .rowbox {
            border-color: #2471c9;
          }
        }
      }

      &.white {
        background-color: #fff;
      }
    }
  }

  &.marginbottom {
    .row {
      margin-bottom: 50px;
    }
  }

  @media(max-width: 1300px) {
    .row {
      padding-left: 20px;

      .details {
        display: block;
        margin-left: 0;
      }

      svg {
        margin-bottom: 20px;
      }
    }
  }

  @media(max-width: 1200px) {
    .row {
      width: calc(100% / 3);
    }
  }

  @media(max-width: 980px) {
    .row {
      width: 50%;
      padding-bottom: 30px;

      &.row2 {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }

      &.row3 {
        width: 100%;
        padding-left: 0;

        &.contact-responsive-margin {
          padding-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .row {
      width: 100%;
      padding-left: 0;

      .inlined {
        .step-arrow {
          display: none;
        }
      }
    }
  }
}

.list {
  .svglist {
    svg {
      font-size: 20px;
      margin-right: 18px;
    }
  }
}

.page-section {
  margin-top: 0;
  margin-bottom: 50px;

  @media (max-width: 480px) {
    margin-bottom: 41px;
  }

  &.fix-margin-bottom {
    margin-bottom: 20px;
  }

  &.last-section {
    margin-bottom: 71px;
  }

  &.custom1 {
    margin-bottom: 75px;
  }

  &.custom2 {
    margin-bottom: 78px;
  }

  &.bordered {
    border-top: 1px solid #dfe3e6;

    .page-title {
      margin-top: 50px;
    }
  }

  &.imaged {
    background-size: 1204px auto;
    background-repeat: no-repeat;

    @media (max-width: 1100px) {
      background-size: auto 100%;
      background-position: center center;
    }
  }


  &.single-quote-image {
    a {
      display: block;
      text-decoration: none;
    }

    label {
      cursor: pointer;
    }

    .quote-image {
      cursor: pointer;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.carousel-arrows {
    position: relative;

    .carouselarrows {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
      left: 0;
      right: 0;
      margin-top: 20px;
      display: none;


      button {
        cursor: pointer;
        background: transparent;
        border: 0;
        appearance: none;
        color: #94989b;
        font-size: 22px;
        outline: none;
        padding: 15px;

        &:focus,
        &:hover {
          outline: none;
        }

        &:hover {
          color: #0a4c98;
          transform: scale(1.5);
          transition: transform 0.2s;
        }

        &#right {
          float: right;
          margin-right: -50px;
        }

        &#left {
          float: left;
          margin-left: -50px;
        }
      }
    }
  }

  fieldset {
    padding: 17px 0;
    box-sizing: border-box;
    display: block;
    margin-top: 3px;

    ul {
      padding-inline-start: 30px;

      li {
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &.list {
      padding: 30px 22px;

      ul, li {
        list-style-type: disc;
      }

      ul {
        margin: 0;
        padding-top: 15px;
      }

      &.white-border {
        border: 1px solid #fff;
      }
    }

    &.nopadding {
      padding-top: 0;
    }

    &.job {
      border: 1px solid #efefef;
      margin: 0 auto 60px auto;
      display: block;
      width: 70%;
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 0;

      @media (max-width: 980px) {
        width: 100%;
      }

      ul {
        display: flex;
        padding-inline-start: 0;
        margin-block-start: 3px;
        margin-block-end: 0;
        justify-content: center;
        list-style-type: none;

        li {
          flex: 0 1 33.3333%;
          text-align: center;
          list-style-type: none;
        }
      }

      @media (max-width: 780px) {
        ul {
          justify-content: flex-start;
          flex-wrap: wrap;

          li {
            flex: 0 1 50%;

            &:last-of-type {
              margin-bottom: 20px;
            }
          }
        }
      }

      @media (max-width: 580px) {
        ul {
          li {
            flex: 0 1 100%;
          }
        }
      }
    }
  }


  .timeline {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 242px;
    align-items: center;

    .year {
      flex: 0 1 20%;
      border-right: 2px solid #e6e6e6;
      text-align: center;
      position: relative;
      box-sizing: border-box;
      padding: 40px 0;

      .infos {
        display: none;
      }

      button {
        display: none;
      }

      i {
        display: block;
        width: 100%;
        height: 18px;

        &:before {
          content: "";
          width: 1px;
          height: 18px;
          background-color: #e6e6e6;
          position: absolute;
          left: calc(100% / 3);
        }

        &:after {
          content: "";
          width: 1px;
          height: 18px;
          background-color: #e6e6e6;
          position: absolute;
          left: 66.6666%;
        }
      }

      b {
        display: block;
        width: 100%;
        height: 18px;

        span {
          position: relative;
          font-size: 21px;
          font-weight: 500;
          top: 46px;
          user-select: none;
          letter-spacing: 1px;
        }

        &:before {
          content: "";
          width: 1px;
          height: 18px;
          background-color: #e6e6e6;
          position: absolute;
          left: calc(100% / 3);
        }

        &:after {
          content: "";
          width: 1px;
          height: 18px;
          background-color: #e6e6e6;
          position: absolute;
          left: 66.6666%;
        }
      }

      img {
        width: 100%;
      }

      &:last-of-type {
        border-right: 0;
      }

      &.current {
        flex: 0 1 44%;
        max-height: 312px;
        border: 14px solid #e6e6e6;
        position: relative;
        left: -2px;
        top: 11px;
        padding-top: 0;
        padding-bottom: 0;

        button {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          background-color: #124f8e;
          appearance: none;
          border-radius: 0;
          width: 45px;
          height: 100px;
          text-align: center;
          color: #fff;
          z-index: 10;
          border: 0;

          &:focus,
          &:hover {
            outline: none;
          }

          svg {
            font-size: 25px;
          }

          &.go-left {
            left: 0;
            margin-left: -32px;
          }

          &.go-right {
            right: 0;
            margin-right: -32px;
          }
        }

        .infos {
          display: block;
          margin-top: 59px;
          line-height: 25px;

          h4 {
            margin: 0 0 18px;
            font-size: 30px;
            color: #0a4c98;
            letter-spacing: 1px;
            user-select: none;
          }
        }

        b,
        i {
          display: none;
        }
      }
    }
  }

  .quote-image {
    background-size: 100% auto;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;

    label {
      display: block;
      width: 70%;
      margin: 0 auto;
      padding: 200px 0;
      color: #fff;
      z-index: 2;
      position: relative;
      text-align: center;
      font-size: 20px;
      font-style: italic;

      p {
        line-height: 34px;
      }
    }

    &.colored {
      label {
        color: #0a4c98;
      }
    }

    .transparent-back {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      background: rgba(0, 55, 115, 0.7);
    }

    @media (max-width: 980px) {
      label {
        padding: 100px 0;
      }
    }

    &.small {
      text-decoration: none;

      &:first-of-type {
        margin-right: 3.5px;
      }

      &:last-of-type {
        margin-left: 3.5px;
      }

      label {
        font-style: normal;
        font-size: 30px;
        letter-spacing: 1px;
        padding: 135px 0;
        font-weight: normal;
        text-decoration: none;
        user-select: none;

        .additional {
          display: block;
          font-size: 14px;
          margin-top: 5px;
          letter-spacing: 1px;
          color: #2ecbea;
        }
      }

      @media (max-width: 880px) {
        background-size: 100% auto;
      }

      @media (max-width: 680px) {
        background-size: auto 100%;
      }
    }

    &.fit {
      label {
        padding: 0;
      }
    }
  }

  .owl-carousel {
    display: block;
    margin-top: 55px;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;

    &.loaded {
      opacity: 1;
    }

    .owl-nav {
      button {
        font-size: 50px;
      }

      .owl-prev {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .owl-next {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.carousel-border {
      .owl-item {
        border: 1px solid #ebebeb;
        box-sizing: border-box;

        @media (hover: hover) {
          &:hover {
            border-color: #7b95c0;
          }
        }

        .custom-item {
          margin: 31px 31px 21px;
          text-align: center;
          height: 300px;

          a {
            text-decoration: none;
          }

          label {
            color: #0a4c98;
            font-weight: 600;
            display: block;
            margin-top: 13px;
            line-height: 22px;
          }

          &.more-margin {
            margin: 25px 25px 21px;
            height: 240px;

            @media (max-width: 500px) {
              height: auto;
            }
          }

          &.product {

            .additional {
              color: #58626c;
              display: block;
              font-size: 14px;
              font-weight: 500;
              margin-top: 0;
              margin-bottom: 30px;
            }
          }

          @media (max-width: 800px) {
            img {
              max-width: 200px;
              display: block;
              margin: auto auto;
            }
          }
        }
      }
    }

    .owl-dots {
      text-align: center;
      margin-top: 31px;
      font-size: 13px;

      button {
        outline: none;
        margin: 0 15px 10px 15px;

        &:focus,
        &:hover {
          outline: none;
        }

        /*@media (min-width: 480px) {
          &:last-of-type {
            margin-right: 0;
          }
        }*/

        @media (max-width: 480px) {
          margin-bottom: 10px;
        }
      }

      .owl-dot {
        span {
          letter-spacing: 1px;
          display: block;
          backface-visibility: visible;
          transition: opacity 0.2s ease;
          height: 18px;
          font-weight: 600;
          border-bottom: 1px solid transparent;
        }

        &.active {
          span {
            color: #0a4c98;
            border-bottom: 1px solid #2ecbea;
          }
        }
      }

      @media (max-width: 480px) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  &.blue {
    background-color: #124f8e;
    color: #6b9edd;
    padding: 47px 81px;
    box-sizing: border-box;

    fieldset {
      border: 1px solid #2966a9;
      color: #fff;

      ul {
        padding-top: 0;
      }

      ul, li {
        list-style-type: disc;
      }
    }

    .tabs__more {
      .banner {
        margin-bottom: 18px;
      }
    }

    p {
      color: #6b9edd;

      &.colored {
        color: #fff;
      }
    }

    .owl-carousel {
      .owl-stage-outer {
        border-left: 1px solid #124f8e;
        border-right: 1px solid #124f8e;
      }

      .owl-dots {
        .owl-dot {
          &.active {
            span {
              color: #fff;
            }
          }
        }
      }
    }

    .inline-grid {
      &.listed {
        .row {
          svg {
            color: #6b9edd;

            &.step-icon {
              position: relative;
              right: 10px;

              @media (max-width: 480px) {
                right: auto;
              }
            }
          }

          b {
            color: #fff;
            display: block;
            font-size: 17px;
            margin-bottom: 5px;
            letter-spacing: 1px;
          }
        }
      }
    }

    @media (max-width: 980px) {
      margin-left: -31px;
      margin-right: -31px;
      overflow: hidden;
      padding: 50px 31px;
    }
  }

  p {
    line-height: 23px;
    margin: 0;
    color: #58626c;
    font-weight: 300;

    &.clause {
      display: block;
      margin-top: 50px;
      font-style: italic;
      color: #94989b;
      margin-bottom: 63px;
    }

    &.margintop {
      margin-top: 43px;
    }
  }
}

.text-input {
  appearance: none;
  border-radius: 0;
  border: 1px solid #e8edf3;
  display: block;
  outline: none;
  height: 50px;
  padding: 0 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;

  &:focus,
  &:hover {
    outline: none;
  }

  &.textarea {
    height: 239px;
    resize: none;
    padding-top: 15px;
    padding-bottom: 15px;

    &.smaller {
      height: 114px;
    }

    &.contact {
      height: 254px;
    }
  }

  &.error {
    border-color: #08478c;
    animation: hideErrorEffect 1s 0.2s;
    animation-fill-mode: forwards;
  }
}

form {
  .text-input {
    margin-bottom: 24px;
  }
}

label {
  color: #55626b;
  display: block;
  margin-bottom: 10px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

html.lb-disable-scrolling {
  overflow: hidden;
  /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;

  a img {
    border: none;
  }

  .lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    *zoom: 1;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 3px;
    border: 4px solid white;
  }

  .lb-outerContainer {
    position: relative;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: white;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .lb-loader {
      position: absolute;
      top: 43%;
      left: 0;
      height: 25%;
      width: 100%;
      text-align: center;
      line-height: 0;
    }

    .lb-cancel {
      display: block;
      width: 32px;
      height: 32px;
      margin: 0 auto;
      background: url("../images/lightbox/loading.gif") no-repeat;
    }

    .lb-nav {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 10;

      a {
        outline: none;
        background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");

        &.lb-prev {
          width: 34%;
          left: 0;
          float: left;
          background: url("../images/lightbox/prev.png") left 48% no-repeat;
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
          opacity: 0;
          -webkit-transition: opacity 0.6s;
          -moz-transition: opacity 0.6s;
          -o-transition: opacity 0.6s;
          transition: opacity 0.6s;
        }

        &.lb-next {
          width: 64%;
          right: 0;
          float: right;
          background: url("../images/lightbox/next.png") right 48% no-repeat;
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
          opacity: 0;
          -webkit-transition: opacity 0.6s;
          -moz-transition: opacity 0.6s;
          -o-transition: opacity 0.6s;
          transition: opacity 0.6s;
        }
      }

      .lb-next,
      .lb-prev {
        height: 100%;
        cursor: pointer;
        display: block;
      }
    }

    .lb-container > .nav {
      left: 0;
    }
  }

  .lb-data {
    padding: 0 4px;
    color: #ccc;

    .lb-details {
      width: 85%;
      float: left;
      text-align: left;
      line-height: 1.1em;
    }

    .lb-close {
      display: block;
      float: right;
      width: 30px;
      height: 30px;
      background: url("../images/lightbox/close.png") top right no-repeat;
      text-align: right;
      outline: none;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
      opacity: 0.7;
      -webkit-transition: opacity 0.2s;
      -moz-transition: opacity 0.2s;
      -o-transition: opacity 0.2s;
      transition: opacity 0.2s;
    }

    .lb-number {
      display: block;
      clear: left;
      padding-bottom: 1em;
      font-size: 12px;
      color: #999999;
    }

    .lb-caption {
      font-size: 13px;
      font-weight: bold;
      line-height: 1em;

      a {
        color: #4ae;
      }
    }
  }
}

.text-center {
  text-align: center;
}

.slider-miniatures {
  .anythingControls {
    position: relative;

    ul {
      right: 0;
      top: 100%;
      margin-top: 40px;
      position: relative;
      display: block;

      li {
        width: 25%;
        display: inline-block;
        margin: 0;
        padding: 0 5px;
        box-sizing: border-box;

        a {
          display: block;
          width: 100%;
        }

        .thumb {
          width: 100%;
          height: 100px;
          background-size: 150px auto;
          background-repeat: no-repeat;
          background-color: #000;
        }

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }

        img {
          height: 100px;
        }
      }
    }
  }
}

.or {
  text-align: center;
  font-weight: 500;
  color: #bbbbbb;
  flex: 0 1 5%;
  @media (max-width: 980px) {
    flex: 0 1 100%;
    padding-bottom: 55px;
  }
}

.rowbox {
  width: auto;
  border: 1px solid #ebeff2;
  display: block;
  margin-bottom: 24px;
  line-height: 25px;
  height: 100%;
  box-sizing: border-box;

  @media (hover: hover) {
    &:hover {
      border-color: #7b95c0;
    }
  }

  &.nohover {
    @media (hover: hover) {
      &:hover {
        border-color: #ebeff2;
      }
    }
  }

  a {
    text-decoration: none;
    display: block;
  }

  &.nomargin {
    margin-bottom: 0;

    .additional {
      margin-bottom: 0;
    }
  }

  .additional {
    color: #58626c;
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .heading-image {
    height: 240px;
    display: block;
    overflow: hidden;
    background-color: #ffffff;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-position: center 30px;
    margin-bottom: 34px;
    position: relative;

    &.full {
      margin: 25px;
      background-position: center center;
      background-size: auto 100%;
      transition: background-size 0.2s ease-in;

      &.nomargin {
        margin-bottom: 0;
      }
    }

    &.cover {
      background-size: cover;
    }

    &.width {
      background-size: 100% auto;
      transition: background-size 0.2s ease-in;
    }

    .icon {
      background-color: #08478c;
      height: 55px;
      width: 55px;
      opacity: 0;
      transition: opacity 0.3s;
      text-align: center;
      position: relative;

      svg {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 22px;
      }
    }
  }

  &.job {
    padding: 50px 0 62px;

    img {
      border: 0;
      max-width: 60px;
    }

    b {
      margin: 25px 25px 0 25px;
    }
  }

  b {
    color: #0a4c98;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    display: block;
    /*margin: 0 36px 12px;*/
    margin: 0 24px 12px;

    &.nomargin {
      margin-bottom: 0;
    }

    @media (max-width: 480px) {
      white-space: normal;
    }
  }

  label {
    margin: 0 36px 26px;
  }

  .controll {
    display: block;
    margin: 0 36px 21px;
    font-size: 13px;

    .time {
      float: right;
      font-weight: 500;
      letter-spacing: 1px;
      color: #989eaa;
      user-select: none;
      margin-top: 7px;
    }

    a {
      color: #2ec4e9;
      text-decoration: none;
      position: relative;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 2px;
      margin-left: 23px;
      position: relative;
      display: inline-block;

      i {
        background-color: #2ec4e9;
        left: -23px;
        width: 15px;
        height: 2px;
        display: block;
        position: absolute;
        top: 18px;
      }

      &.more-button {
        color: #08478c;
        padding-left: 7px;

        i {
          height: 1px;
        }
      }
    }

    &.small-margin {
      margin-left: 14px;
    }
  }

  &:hover {
    .heading-image.full {
      background-size: auto 110%;
      transition: background-size 0.2s ease-in;
    }

    .heading-image.full.width {
      background-size: 110% auto;
      transition: background-size 0.2s ease-in;
    }
  }
}

.pointer {
  .rowbox {
    &:hover {
      border-color: #7b95c0;
    }
  }
}

.paginate {
  display: block;
  list-style-type: none;
  text-align: center;
  user-select: none;
  color: #58626c;
  font-size: 13px;
  cursor: default;

  li {
    display: inline-block;
    text-decoration: none;
    padding: 0;
    height: 18px;
    text-align: center;
    outline: 0;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 34px 0 0;
    cursor: pointer;

    &.current {
      color: #0a4c98;
      border-bottom: 1px solid #2ecbea;
    }
  }
}

.rotator {
  &.hidden-rotator {
    display: none;
  }
}

.intro-boxes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-left: -10px;
  margin-right: -10px;

  .box {
    position: relative;
    border: 1px solid #e8edf3;
    box-sizing: border-box;
    margin: 0 10px 20px 10px;
    display: inline-block;
    background-color: #fff;
    height: 360px;
    padding: 2px 39px;

    p {
      color: inherit;
      font-weight: 300;
      font-size: 15px;
    }

    iframe {
      border: 0;
      width: 100%;
    }

    a {
      text-decoration: none;
    }

    .button-box {
      text-decoration: none;
      color: #08478c;
      outline: none;
      margin-top: 20px;
      margin-bottom: 20px;
      display: block;
      text-transform: uppercase;
      position: relative;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 3px;
      padding: 10px 15px 10px 30px;
      width: 70px;

      span {
        &:before {
          content: '';
          background-color: #2ec4e9;
          height: 1px;
          display: block;
          position: absolute;
          margin-left: -28px;
          top: 16px;
          width: 13px;
        }
      }
    }

    h4 {
      font-size: 26px;
      color: #08478c;
      font-weight: 400;
      text-decoration: none;
    }

    &.box1 {
      width: calc(54% - 20px);
      position: relative;
      overflow: hidden;
      margin-bottom: 50px;

      &:hover {
        .play {
          background-image: url("../images/ico_play_h.png");
        }
      }

      iframe {
        opacity: 0;
        transition: opacity 0.3s;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        height: 101%;
        width: 101%;
      }

      &.show-movie {
        .preview {
          display: none;
        }

        .play {
          display: none;
        }

        iframe {
          opacity: 1;
          transition: opacity 0.3s;
        }
      }


      @media (max-width: 1115px) {
        width: calc(100% - 20px);
        margin-bottom: 50px;
      }
    }

    &.box2 {
      width: calc(46% - 20px);
      margin-bottom: 50px;

      .content {
        font-size: 15px;
        font-weight: 300;
        line-height: 23px;
      }

      @media (max-width: 1115px) {
        width: calc(50% - 20px);
        margin-bottom: 50px;
        height: 453px;
      }

      @media (max-width: 980px) {
        height: auto;
      }
    }

    &.box3 {
      width: calc(28% - 20px);
      background-color: #e1e8f0;
      padding: 12px 10px 20px 10px;
      height: 453px;
      position: relative;
      text-align: center;
      margin-bottom: 0;

      @media (max-width: 1115px) {
        width: calc(50% - 20px);
        margin-bottom: 50px;
      }

      @media (max-width: 980px) {
        height: auto;
      }
    }

    &.box4 {
      width: calc(72% - 20px);
      overflow: hidden;
      padding: 12px 47px 20px 47px;
      height: 453px;
      margin-bottom: 0;

      @media (max-width: 1115px) {
        width: calc(100% - 20px);
      }
    }

    @media (max-width: 980px) {
      width: calc(100% - 20px) !important;
      margin-bottom: 59px;

      &.box3 {
        margin-bottom: 59px;
      }
    }

    @media (max-width: 580px) {
      margin-bottom: 41px;

      &.box4 {
        padding-bottom: 41px !important;
        padding-right: 47px !important;
        height: auto !important;
      }

      &.box3 {
        margin-bottom: 41px;
      }
    }

    @media (max-width: 480px) {
      margin-bottom: 41px;
    }
  }
}


.play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-image: url("../images/ico_play.png");
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  opacity: 1;
  transition: opacity 0.3s;
  cursor: pointer;
}

.preview {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.3s;
  opacity: 1;
  cursor: pointer;
}

.no-margin {
  .quote-image.small {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

h3 {
  &.colored {
    color: #0a4c98;
    letter-spacing: 1px;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 20px;
  }
}

.carousel-circular {
  padding: 0;
  margin: 0;
  width: calc(100% - 30px);
  display: inline-block;
  padding-bottom: 30px;

  .item-circular {
    display: none;
    width: 200px;
    background-size: 100%;
    vertical-align: middle;
    position: relative;
    margin: auto auto;

    img {
      max-width: 100%;
    }

    a {
      display: block;
      width: 100%;
    }

    &.transition {
      transition: all 0.35s ease;
    }

    &.active {
      display: block;
    }

    @media (max-width: 980px) {
      width: calc(100% - 30px);
    }
  }
}

.tabs__more {
  width: 261px;
  margin: 30px auto 10px auto;
  display: block;
}

.main-slider {
  position: relative;
  padding-bottom: 0;

  .slider-numbers {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    margin: 0;
    list-style-type: none !important;
    padding-top: 20px;

    li {
      text-indent: 0;
      font-size: 13px;
      color: #fff;
      margin-right: 33px;
      font-weight: 400;
      letter-spacing: 1px;
      height: 39px;
      text-align: center;
      position: relative;
      cursor: pointer;
      list-style-type: none !important;
      -webkit-tap-highlight-color: transparent;

      &:hover, &:focus {
        -webkit-tap-highlight-color: transparent;
      }

      &.current {
        &:before {
          content: '';
          width: 1px;
          height: 21px;
          background: #2ecbea;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
        }
      }
    }
  }
}

.home-slider {
  max-width: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: 740px) {
    height: 450px;
  }

  video {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    @media (max-width: 740px) {
      display: none;
    }
  }

  .home-slider-image {
    display: block;

    img {
      max-width: 100%;
    }

    &.show-responsive {
      display: none;

      @media (max-width: 740px) {
        display: block;
      }
    }

    &.hide-responsive {
      @media (max-width: 740px) {
        display: none;
      }
    }

    @media (max-width: 740px) {
      height: 450px;
      position: relative;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 450px;
        width: auto;
        max-width: none;
      }
    }
  }

  .home-slider-captions {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-weight: bold;
    font-size: 71px;
    display: inline-block;
    left: 70px;
    max-width: 45%;
    line-height: 78px;
    z-index: 2;

    div {
      display: none;
    }

    button {
      margin-top: 44px;
    }

    i {
      font-size: 15px;
      text-transform: uppercase;
      color: #a5a3a3;
      display: block;
      font-style: normal;
      line-height: 0;
      margin-top: 27px;
      font-weight: normal;
      letter-spacing: 2px;
    }

    @media (max-width: 705px) {
      max-width: 80%;
      font-size: 41px;
      line-height: 45px;

      i {
        margin-bottom: 27px;
        margin-top: 27px;
      }
    }

    @media (max-width: 480px) {
      left: 30px;
      transform: translate(0, -50%);

      i {
        display: none;
      }
    }
  }

  .home-slider-dots {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background: url("../slider/home-slider-dots.png") no-repeat;
    background-size: 1200px auto;
    z-index: 1;
  }
}

.home-tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  &__tabs {
    width: 29%;
  }

  &__container {
    width: 71%;
  }

  .mobile-enabled {
    display: none;
    max-width: 100%;

    &.showed {
      @media (max-width: 820px) {
        display: block;
        margin: 20px auto;
      }
    }
  }

  .tab-link {
    background: none;
    color: #fff;
    display: block;
    padding: 27px 30px 20px 0;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid rgba(232, 237, 243, .3);

    &:last-of-type {
      border: 0;
    }

    &:hover {
      color: #2ecbea;
    }

    &.current {
      color: #2ecbea;

      @media (max-width: 820px) {
        border-bottom: 0;
      }

      &:before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 2px;
        position: relative;
        top: -3px;
        margin-right: 15px;
        background: #2ecbea;
      }
    }
  }

  @media (max-width: 820px) {
    .home-tabs__tabs {
      flex-basis: 100%;
    }

    .home-tabs__container {
      display: none;
    }
  }

  .tab-content {
    display: none;

    img {
      max-width: 100%;
    }

    &.current {
      display: block;
      cursor: pointer;
    }
  }
}

.lslide {
  &.active {
    .slider-container {
      img {
        @media (max-width: 1170px) {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
  }
}

.inline-news-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  top: 20px;
  touch-action: none;

  @media (max-width: 480px) {
    overflow: hidden;
    touch-action: none;
  }


  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.inline-news-scroll-cover {
  overflow: hidden;
  touch-action: none;
}

.inline-news {
  position: relative;
  width: calc(6 * 402px);
  white-space: nowrap;
  margin-left: -40px;
  margin-right: -40px;

  .news-row {
    display: inline-block;
    box-sizing: border-box;
    padding: 0 40px;
    float: left;
    white-space: normal;
    margin-bottom: 30px;

    .intro {
      font-size: 15px;
      line-height: 23px;
      display: block;
      font-weight: 300;
    }

    time {
      display: block;
      font-size: 13px;
      margin: 11px 0 22px;
      color: #08478c;
    }

    b {
      display: block;
      color: #2ecbea;
      font-weight: 500;
      line-height: 26px;
      font-size: 20px;
    }
  }

  @media (max-width: 800px) {
    margin-left: 0;
    margin-right: 0;

    .news-row {
      padding: 0;
    }
  }
}

.navbar {
  height: 84px;
  padding-bottom: 0;
  z-index: 100;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin-right: 0;
  box-shadow: 0 4px 5px -3px transparent;
  z-index: 99;

  .container {
    width: 1366px;
    display: block;
    box-sizing: border-box;
    position: relative;
    margin: auto;

    @media (max-width: 1366px) {
      margin-left: 81px;
    }

    @media (max-width: 980px) {
      padding-left: 31px;
      padding-right: 31px;
      margin-left: 0;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  &.floatingNav {
    box-shadow: 0 4px 8px -3px rgba(0, 0, 0, 0.15);
  }
}

.inline-news__count {
  position: absolute;
  right: 24px;
  bottom: 0;
  display: flex;

  span {
    cursor: pointer;
    margin-left: 33px;
    height: 38px;
    padding: 0;
    position: relative;
    text-indent: 0;
    font-size: 13px;
    background: 0 0;
    width: auto;
    letter-spacing: 1px;
    display: block;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    transition: opacity .2s ease;
    font-weight: 400;

    &.mobile {
      display: none;
    }

    &.current {
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        top: 21px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background: #2ecbea;
      }
    }
  }

  @media (max-width: 800px) {
    span {
      &.mobile {
        display: inline-block;
      }
    }
  }
}

.list-loading {
  display: block;
  margin-top: 40px;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
    transition: opacity 0.2s;
  }
}

.blockhref {
  display: block;
  cursor: pointer;
  text-decoration: none;
}

.tabs {
  list-style-type: none;
  padding: 0;
  clear: both;
  overflow: hidden;
  display: flex;

  .tabicon {
    max-width: 25px;
    border: 0;
    margin-top: 8px;
    margin-bottom: 5px;
  }

  .tabicon-hovered {
    max-width: 25px;
    border: 0;
    margin-top: 8px;
    margin-bottom: 5px;
  }

  a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: #58626e;
    text-align: center;
    user-select: none;
    letter-spacing: 1px;
    padding: 13px 47px 15px;
    box-sizing: border-box;
    float: left;

    .tabicon-hovered {
      display: none;
    }

    svg {
      font-size: 20px;
      margin-bottom: 10px;

    }

    &.active {
      background: #fff;
      color: #0a4c98;

      @media (max-width: 480px) {
        .tabicon {
          display: none;
        }

        .tabicon-hovered {
          display: inline-block;
        }
      }
    }
  }

  @media (max-width: 980px) {
    a {
      width: calc(100% / 3);
      text-align: center;
      padding-left: 0;
      padding-right: 0;

      svg {
        display: block;
        float: none;
        margin: 0 auto 10px auto;
      }
    }
  }

  @media (max-width: 480px) {
    a {
      font-size: 0;
      background: #fff;

      &.active {
        background: #08478c;
      }
    }
  }
}

.tabs-container.js .tab-content {
  display: none;
  padding: 40px;
  box-sizing: border-box;
  background: #fff;
  line-height: 23px;
  color: #58626c;
  font-weight: 300;

  img {
    max-width: 100%;
  }

  &.active {
    display: block;
  }

  &.download-padding {
    padding-bottom: 27px;
  }

  .table-scroller {
    max-width: 100%;
    overflow-x: auto;
  }
}

.tab-list {
  list-style-type: none;
  border: 1px solid #dfe3e6;
  vertical-align: top;
  padding-inline-start: 0;
  list-style-type: none !important;

  li {
    padding: 20px 37px;
    font-size: 16px;
    letter-spacing: 1px;
    color: #0a4c98;
    font-weight: 600;
    border-bottom: 1px solid #dfe3e6;
    user-select: none;
    list-style-type: none !important;

    &.iconed {
      svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        font-size: 25px;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.half-image {
  width: 100%;
  border: 0;
}

.content-time {
  border: 1px solid #dfe3e6;
  display: inline-block;
  font-size: 13px;
  padding: 11px 32px;
  margin-bottom: 20px;
  user-select: none;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 3px;
}

.inlined {
  display: flex;

  p {
    width: 100%;
  }

  .step-arrow {
    display: inline-block;
    position: relative;
    top: -50px;

    svg {
      &.hidden {
        color: transparent !important;
      }
    }
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.filelabel {
  appearance: none;
  border-radius: 0;
  border: 1px solid #e8edf3;
  outline: 0;
  height: 50px;
  padding: 0 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  &.error {
    border-color: #08478c;
    animation: hideErrorEffect 1s 0.2s;
    animation-fill-mode: forwards;
  }

  .choosed-filename {
    position: relative;
    top: 15px;
    max-width: 90%;
    overflow: hidden;
    display: inline-block;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    &.uploadicon {
      color: #08478c;
      font-size: 20px;
      vertical-align: middle;
      position: absolute;
      right: 5px;
      top: 14px;
    }
  }
}

.after-info {
  display: none;
  user-select: none;
  color: #0a4c98;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;

  &.active {
    animation: showSendinfo 0.3s;
    animation-fill-mode: forwards;
    display: block;

    @keyframes showSendinfo {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}

svg {
  &.title-icon {
    color: #2ec4e9;
    display: inline-block;
    margin-right: 5px;
    width: 35px;
  }
}

.formrows {
  display: block;

  &.description {
    font-size: 14px;
    color: #0a4c98;
  }

  .heading {
    color: #0a4c98;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1px;
    display: block;
    margin-top: 41px;
    margin-bottom: 41px;

    &.small-margin {
      margin-bottom: 20px;
    }

    &.no-margin-top {
      margin-top: 0;
    }
  }

  .formline {
    display: flex;
    margin-top: 11px;

    &.marginbottom {
      margin-bottom: 35px;
    }

    @media (max-width: 1255px) {
      flex-wrap: wrap;
    }

    .formbox {
      flex: 1;
      box-sizing: border-box;
      padding: 0 10px;

      .text-input {
        margin-bottom: 0;
      }

      &.upload {
        width: 150px;

        &:hover {
          svg {
            animation: spinIcon 0.5s;
            animation-fill-mode: forwards;
            @keyframes spinIcon {
              0% {
                transform: rotate(0);
              }

              100% {
                transform: rotate(90deg);
              }
            }
          }
        }

        @media (max-width: 480px) {
          padding-left: 0;
        }
      }

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      &.smallest {
        flex: 0 1 160px;

        @media (max-width: 480px) {
          flex: 1 0 100%;
          padding-left: 0;

          &.mobile-margin {
            margin-top: 20px;
          }
        }
      }

      &.smaller {
        flex: 0 1 300px;
      }

      &.half {
        flex: 0 1 25%;

        @media (max-width: 1255px) {
          flex: 1 0 50%;
        }

        @media (max-width: 480px) {
          flex: 1 0 100%;

          &.mobile-margin {
            margin-top: 20px;
          }
        }
      }

      &.last {
        flex-grow: 0;
      }

      @media (max-width: 1255px) {
        padding-left: 0;

        &.mediumpadding {
          padding-top: 22px;
        }
      }

      @media (max-width: 607px) {
        &.smallpaddingo {
          padding-top: 22px;
        }

        &.smallpaddingo2 {
          padding-top: 12px;
        }
      }

      @media (max-width: 548px) {
        &.smallpaddingo3 {
          padding-top: 22px;
        }
      }

      @media (max-width: 480px) {
        flex: 1 0 100%;
        padding-left: 0;
      }
    }

    @media (max-width: 480px) {
      flex-wrap: wrap;
    }

    &.no-margin-link {
      .link-formline {
        margin-left: 0;
      }
    }
  }

  &.bigger-margin {
    .formline {
      margin-top: 21px;
    }
  }
}

.input-select {
  background-size: 100% auto;
  background: url("../images/select-arrow.jpg") calc(100% - 15px) 49% no-repeat;
  width: 100%;
  height: 50px;
}

.inline-radios {
  padding-top: 11px;

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  i {
    width: 16px;
    height: 16px;
    border: 1px solid #e8edf3;
    display: inline-block;
    margin-right: 2px;
    position: relative;
    top: 4px;
  }

  label {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 0;
    margin-right: 8px;
    user-select: none;

    &.error {
      i {
        border-color: #08478c;
        animation: hideErrorEffect 1s 0.2s;
        animation-fill-mode: forwards;
      }
    }

    &.agree {
      font-size: 14px;
      display: flex;
      font-weight: 300;

      ol {
        list-style-type: decimal;

        padding-top: 0;
        margin-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        margin-block-start: 0;

        li {
          list-style-type: decimal;
          padding-top: 0;
          margin-top: 0;
          padding-bottom: 0;
          margin-bottom: 0;
          margin-block-start: 0;
        }
      }

      i {
        flex: 0 0 16px;
        margin-right: 10px;
        position: relative;
        top: -3px;
      }

      a {
        display: inline-block;
      }
    }

    &.checked {
      i {
        border-color: #0a4c98;

        &:before {
          content: "";
          position: absolute;
          left: 1px;
          top: 1px;
          right: 1px;
          background: #0a4c98;
          bottom: 1px;
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

#cv-form {
  margin-top: 20px;
}

@keyframes hideErrorEffect {
  0% {
    border-color: #08478c;
  }

  40% {
    border-color: #e8edf3;
  }

  60% {
    border-color: #08478c;
  }

  100% {
    border-color: #e8edf3;
  }
}

.lSSlideWrapper {
  border: 0;
}

.main-slider {
  .lSSlideWrapper {
    border: 0;
  }
}

.slider-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
}

.slider-filler {
  opacity: 0;

  @media (max-width: 680px) {
    min-height: 50vh;
  }
}


.horizontal {
  .lSSlideWrapper {
    border: 0;
  }
}

.lSSlideOuter .lSPager.lSGallery {
  margin: 0;
  padding: 0;
  padding-inline-start: 0;
  margin-block-start: 0;

  li {
    display: inline;
  }
}

.lSSlideOuter.horizontal .lSPager.lSGallery li {
  border: 0;
  max-width: calc(20% - 7.5px);
}

.lightSlider {
  li {
    position: relative;
    list-style-type: none !important;

    .caption {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      font-weight: bold;
      font-size: 71px;
      display: inline-block;
      left: 70px;
      max-width: 50%;
      line-height: 78px;
      z-index: 2000000;
      backface-visibility: hidden;

      button {
        margin-top: 44px;
      }

      i {
        font-size: 15px;
        text-transform: uppercase;
        color: #a5a3a3;
        display: block;
        font-style: normal;
        line-height: 0;
        margin-top: 27px;
        font-weight: normal;
        letter-spacing: 2px;
      }

      @media (max-width: 705px) {
        max-width: 80%;
        font-size: 45px;
        line-height: 45px;

        i {
          margin-bottom: 27px;
          margin-top: 27px;
        }
      }

      @media (max-width: 480px) {
        left: 50%;
        transform: translate(-50%, -50%);

        i {
          display: none;
        }
      }
    }

    img {
      height: auto;
      max-width: 100%;
      display: block;
      box-sizing: border-box;

      @media (max-width: 1100px) {
        min-height: 450px;
        height: 450px;
        max-width: none;
      }

      @media (max-width: 480px) {
        max-width: none;
        //min-height: auto;
      }
    }
  }

  &.homepage {
    li {
      img {
        @media (max-width: 480px) {
          //max-width: none;
          min-height: auto;
          height: 60vh;
        }
      }

      .slider-container {
        img {
          height: 100%;
        }
      }
    }
  }
}

.page-section.same-bottom {
  padding-bottom: 1px;
}

.slider-cover {
  .lightSlider li img {
    min-height: 0;
    height: auto;
    max-width: 100%;
    /*position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);*/
  }
}

.lSSlideOuter {
  /*padding-right: 113px !important;*/
  position: relative;

  .vertical-controlls {
    appearance: none;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    position: absolute;
    padding: 0;
    margin: 0;
    right: 40px;

    &:focus, &:hover {
      outline: none;
    }

    &:hover {
      svg {
        &.arrow {
          color: #08478c;
        }
      }
    }

    svg {
      &.arrow {
        color: #d2d2d2;
      }
    }

    &#prev {
      top: -11px;
    }

    &#next {
      bottom: -9px;
    }
  }

  .lSPager.lSGallery {
    li {
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      height: auto !important;

      &.active {
        border-radius: 0;
      }

      a {
        img {
          width: 100%;
        }
      }

      &:hover {
        border-radius: 0;
      }
    }
  }
}

.lSSlideOuter.vertical {
  padding-right: 130px !important;

  .lSPager {
    transform: none !important;
  }
}

.lSSlideOuter:not(.vertical):not(.horizontal) .lSPager.lSGallery {
  width: 100% !important;

  li {
    width: 110px !important;
  }
}

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;

    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');

    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before {
      transition-delay: 0s, 0s, $duration;
    }

    &::after {
      transition-delay: 0s, $duration, 0s;
    }
  }
}

.draw-border {
  @media (hover: hover) {
    border: 0 !important;
    @include btn-border-drawing(#2ecbea, #fff, 1px, bottom, right);
  }
  transition: background 0.2s;

  &:hover {
    span {
      &:before {
        background-color: #fff;
        transition: background 0.2s;
      }
    }
  }
}

.draw-border-no-background {
  @media (hover: hover) {
    border: 0 !important;
    @include btn-border-drawing(#fff, #0a4c98, 1px, bottom, right);
  }
  transition: background 0.2s;

  span {
    &:before {
      transition: background 0.2s;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: transparent;

      span {
        &:before {
          background-color: #0a4c98 !important;
          transition: background 0.2s;
        }
      }
    }
  }
}

.pager {
  .lSPager {
    position: absolute;
    bottom: 0;
    right: 40px;
    opacity: 1;
    display: none;

    &.loaded {
      opacity: 1;
    }

    &.lSpg {
      margin: 0;

      li {
        margin-left: 33px;
        height: 45px;
        padding: 0;
        position: relative;

        a {
          text-indent: 0;
          font-size: 13px;
          border-radius: 0;
          background: transparent;
          width: auto;
          height: auto;
          letter-spacing: 1px;
          display: block;
          backface-visibility: visible;
          transition: opacity .2s ease;
          font-weight: normal;
          text-decoration: none;
          color: #fff;
        }

        &.active {
          &:before {
            content: '';
            position: absolute;
            width: 1px;
            top: 21px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: #2ecbea;
          }

          a {
            background-color: transparent;
            color: #fff;
          }
        }

        &:hover {
          a {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.slider-cover {
  width: 600px;
  box-sizing: border-box;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  margin-top: 30px;
  min-height: 200px;

  &.white-background {
    .slick-dots li button {
      color: #58626c;
    }

    .slick-dots li.slick-active button {
      color: #0a4c98;
    }
  }
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  min-height: 200px;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  @media (max-width: 600px) {
    transition: all .5s ease-out;
  }
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-height: 200px;
  transition: all 0.1s;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }

  figure {
    margin: 0;
    padding: 0;
    width: 25%;
    position: relative;

    @media (max-width: 600px) {
      width: 100%;
    }

    img {
      width: 100%;
    }

    iframe {
      width: 100%;
      border: 0;
      appearance: none;
    }
  }
}

.slick-slider.border {
  margin-left: -13px;
  margin-right: -10px;

  figure {
    box-sizing: border-box;
    padding: 25px;
    position: relative;
    text-align: center;
    outline: none;
    color: #0a4c98;
    font-weight: 600;
    border: 1px solid #ebebeb;
    margin: 10px;

    &:last-of-type {
      margin-right: 0;
    }

    @media (hover: hover) {
      &:hover {
        border-color: #7b95c0;
      }
    }

    a {
      outline: none;
      text-decoration: none;
    }

    label {
      margin-top: 10px;
      outline: none;

    }
  }
}

.slick-slider.news {
  margin-left: -13px;
  margin-right: -10px;
  position: static;


  @media (max-width: 480px) {
    padding-bottom: 30px;
  }

  figure {
    box-sizing: border-box;
    padding: 0;
    position: relative;
    text-align: left;
    outline: none;
    color: #0a4c98;
    font-weight: 600;
    border: 0;
    margin: 10px;
    padding-right: 20px;

    @media (max-width: 780px) {
      padding-right: 0;
    }

    b {
      display: block;
      color: #2ecbea;
      font-weight: 500;
      line-height: 26px;
      font-size: 20px;
    }

    time {
      display: block;
      font-size: 13px;
      margin: 11px 0 22px;
      color: #08478c;
    }

    .intro {
      font-size: 15px;
      line-height: 23px;
      display: block;
      font-weight: 300
    }

    .button-box {
      text-decoration: none;
      color: #08478c;
      outline: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      display: block;
      text-transform: uppercase;
      position: relative;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 3px;
      padding: 10px 15px 10px 30px;
      width: 70px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media (hover: hover) {
      &:hover {
        border-color: #7b95c0;
      }
    }

    a {
      outline: none;
      text-decoration: none;
    }

    label {
      margin-top: 10px;
      outline: none;
    }
  }

  .slick-dots {
    position: absolute;
    right: 24px;
    bottom: 22px;
    display: flex;
    width: auto;

    li {
      margin-bottom: -2px;
      height: 32px;

      button {
        cursor: pointer;
        margin-left: 33px;
        height: 38px;
        padding: 0;
        position: relative;
        text-indent: 0;
        font-size: 13px;
        background: 0 0;
        width: auto;
        letter-spacing: 1px;
        display: block;
        transition: opacity .2s ease;
        font-weight: 400;
        color: #58626c;
      }

      &.slick-active {
        button {
          border-bottom: none !important;

          &:before {
            content: '';
            position: absolute;
            width: 1px;
            top: 33px;
            bottom: 0;
            height: 19px;
            left: 50%;
            transform: translateX(-50%);
            background: #2ecbea;
          }
        }
      }
    }

    @media (max-width: 480px) {
      right: auto;
      left: 50%;
      transform: translateX(-50%);

      li {
        &:first-of-type {
          button {

            margin-left: 0;

          }
        }
      }
    }
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: inline-block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  &:hover, &:focus {
    outline: none;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}


.slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  margin-top: 30px;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    margin-bottom: 5px;

    button {
      font-size: 13px;
      line-height: 0;
      display: block;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: #6b9edd;
      border: 0;
      outline: none;
      background: transparent;
      font-weight: 600;

      &:hover, &:focus {
        outline: none;
      }
    }

    &.slick-active {
      button {
        border-bottom: 1px solid #2ecbea !important;
        color: #fff;
      }
    }
  }
}

.slick-slider.single, .slick-slider.border {
  .slick-track figure {
    width: 100%;
  }

  .slick-dots {
    li {
      button {
        color: #58626c;
        border-bottom: 1px solid transparent;
      }

      &.slick-active {
        button {
          color: #0a4c98;
        }
      }
    }
  }
}

.job-desc {
  text-align: center;
  display: block;
  margin-bottom: 50px;
}

.number-list {
  list-style-type: decimal;

  li {
    list-style-type: decimal;
  }
}

.hide-background {
  border: 0;
  box-shadow: none;

  &:hover {
    box-shadow: inset 0 0 0 1px #fff;
    background: transparent;
    color: #0a4c98;
    border: 1px solid #fff;

    span {
      &:before {
        background: #0a4c98;
      }
    }
  }
}

.light-font {
  font-weight: 300;
}

.font-small {
  font-size: 14px;
}

.link-formline {
  color: #0a4c98;
  text-decoration: none;
  margin-left: 10px;
}

.slick-slide {
  &:hover {
    .play {
      background-image: url("../images/ico_play_h.png");
    }
  }
}

.show-movie {
  .preview {
    display: none;
  }

  .play {
    display: none;
  }

  iframe {
    display: block;
  }
}

.newsText {
  color: #58626c;
}

a {
  label {
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

.infos {
  .inline-grid {
    .row.rowthree {
      padding: 25px;
    }
  }
}

.page-section {
  color: #58626c;

  a {
    text-decoration: none;
    color: #0a4c98;
  }

  li, ul {
    list-style-type: disc;
  }
}

.after-infobox {
  margin-top: 30px;
}


.default-icon {
  border: 0;
  max-width: 25px;
  display: inline-block;
  vertical-align: middle;
}

.infowindow {
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  color: #94989b;
  font-weight: 300;
  padding: 10px 20px 20px 20px;
  text-align: center;

  a {
    display: block;
    margin-top: 10px;
    color: #0a4c98;
    text-decoration: none;

    &:hover {
      color: #0a4c98;
      text-decoration: none;
    }
  }

  h2 {
    font-size: 13px;
  }
}

.slider-mobile-enabled {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.slider-mobile-disabled {
  @media (max-width: 768px) {
    display: none;
  }
}

button.gm-ui-hover-effect {
  right: 0 !important;
  top: 0 !important;
}

.gm-style .gm-style-iw-t::after {
  display: none !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 0;
  max-height: none !important;
  max-width: none !important;
}

.gm-style-iw-d {
  max-height: none !important;
  max-width: none !important;
}

@media (max-width: 980px) {
  .contact-send {
    margin-top: -30px;
  }
}

.owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}


#scrollToTop {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 1250px;
  padding-left: 81px;
  display: none;

  &.visible {
    display: flex;
    justify-content: flex-end;
  }

  a {
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: #08478c;
    display: inline-block;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1350px) {
    left: 0;
    right: 0;
    width: auto;
  }
}

.download-padding {
  .inline-grid {
    margin-left: 0;
    margin-right: 0;
  }
}