@font-face {
  font-family: 'Metropolis';
  src: url("../fonts/Metropolis-Light.eot");
  src: url("Metropolis-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Metropolis-Light.woff2") format("woff2"), url("../fonts/Metropolis-Light.woff") format("woff"), url("../fonts/Metropolis-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url("../fonts/Metropolis-Medium.eot");
  src: url("../fonts/Metropolis-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Metropolis-Medium.woff2") format("woff2"), url("../fonts/Metropolis-Medium.woff") format("woff"), url("../fonts/Metropolis-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url("../fonts/Metropolis-Bold.eot");
  src: url("../fonts/Metropolis-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Metropolis-Bold.woff2") format("woff2"), url("../fonts/Metropolis-Bold.woff") format("woff"), url("../fonts/Metropolis-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/** {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}*/
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  padding: 0;
  margin: 0;
  font-family: 'Metropolis', sans-serif;
  font-size: 16px;
  color: #94989b;
  font-weight: 300;
  overflow-x: hidden;
  zoom: 1;
}

#preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#preloader .progress {
  position: relative;
}

#preloader .progress .progress-bar {
  background: #0a4c98;
  height: 3px;
  transition: 0.1s;
}

#page {
  width: 1362px;
  opacity: 0;
  margin: auto;
  padding-left: 81px;
  box-sizing: border-box;
  position: relative;
  margin-top: 81px;
}

@media (max-width: 1366px) {
  #page {
    width: auto;
    margin: 0;
    margin-top: 81px;
  }
}

@media (max-width: 980px) {
  #page {
    padding-left: 31px;
  }
}

body.loaded #page {
  opacity: 1;
}

body.loaded #preloader {
  display: none;
}

nav {
  padding-top: 28px;
  margin-bottom: 14px;
  user-select: none;
  display: flex;
  transition: padding 0.2s;
}

.prefoot {
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
}

.prefoot ul, .prefoot li {
  list-style-type: none !important;
}

@media (max-width: 1170px) {
  .prefoot .separate-mobile {
    display: block;
    height: 20px;
    flex-basis: 100%;
  }
}

.prefoot .foot-row {
  flex: 1 1 20%;
}

.prefoot .foot-row ul {
  padding: 0;
  padding-top: 2px;
  margin: 0;
  margin-top: 15px;
  margin-left: 0;
}

.prefoot .foot-row ul li {
  margin-bottom: 13px;
  margin-left: 0;
}

.prefoot .foot-row ul li.last {
  margin-top: 21px;
}

.prefoot .foot-row ul li:last-of-type {
  margin-bottom: 0;
}

.prefoot .foot-row a {
  text-decoration: none;
  color: #58626c;
  user-select: none;
  transition: color 0.2s;
  font-size: 15px;
}

.prefoot .foot-row a:hover {
  color: #0a4c98;
  transition: color 0.2s;
}

.prefoot .foot-row a.link-title {
  color: #0a4c98;
  font-size: 19px;
  border-bottom: 1px solid #e8edf3;
}

.prefoot .foot-row b {
  border-bottom: 1px solid #b2bcd5;
  font-size: 18px;
  color: #0a4c98;
}

.prefoot .foot-row:last-of-type {
  flex: 0 1 18px;
}

@media (max-width: 1170px) {
  .prefoot .foot-row {
    flex: 0 1 49%;
  }
  .prefoot .foot-row:last-of-type {
    display: none;
  }
}

@media (max-width: 480px) {
  .prefoot .foot-row {
    flex: 0 1 100%;
  }
}

@media (max-width: 1170px) {
  .prefoot .foot-row.margined {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .prefoot .foot-row.margined-mobile {
    margin-top: 35px;
  }
}

footer {
  border-top: 1px solid #e8edf3;
  padding-top: 57px;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 56px;
  margin-top: 59px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer .footer-text {
  padding-right: 10px;
  margin-bottom: 5px;
}

footer .vilsone-logo img {
  border: 0;
  vertical-align: middle;
  display: inline-block;
  margin-left: 5px;
}

@media (max-width: 480px) {
  footer .vilsone-logo {
    float: none;
    display: block;
    margin-bottom: 5px;
  }
}

.selectable {
  appearance: none;
  border-radius: 0;
  border: 1px solid #e8edf3;
  display: inline-block;
  cursor: pointer;
  outline: none;
  padding: 7px 12px;
  color: #55626b;
}

.selectable:focus, .selectable:hover {
  outline: none;
}

.selectable.error {
  border-color: #08478c;
  animation: hideErrorEffect 1s 0.2s;
  animation-fill-mode: forwards;
}

.logotype {
  float: left;
  position: relative;
  top: -10px;
}

.logotype img {
  border: 0;
  height: 52px;
}

@media (max-width: 480px) {
  .logotype img {
    width: auto;
    height: 52px;
    margin-top: 3px;
  }
}

aside {
  float: right;
  display: block;
  right: 0;
  top: 0;
  width: 81px;
  transition: width 0.4s, opacity 0.1s;
  position: fixed;
  z-index: 100;
  height: auto;
  opacity: 0;
}

aside.loaded {
  opacity: 1;
  transition: opacity 0.1s;
}

aside ul {
  display: block;
  margin: auto;
  width: 18px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding-top: 56px;
}

@media (min-width: 1365px) {
  aside {
    right: auto;
    position: relative;
  }
}

@media (max-width: 1000px) {
  aside {
    height: 80px;
  }
}

.socials {
  display: block;
  margin: auto;
  width: 18px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding-top: 56px;
  list-style-type: none;
}

@media (max-width: 980px) {
  .socials {
    display: none;
  }
}

.socials li {
  text-align: center;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 22px;
  padding-top: 24px;
  width: 18px;
}

.socials li a {
  color: #99a0a8;
  transition: color 0.2s;
}

.socials li:hover a {
  color: #0b4790;
  transition: color 0.2s;
}

.socials li:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}

.socials li:first-of-type {
  padding-top: 0;
}

#cookiebox {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f0f4f7;
  display: none;
  z-index: 105;
}

#cookiebox.visible {
  display: block;
}

#cookiebox .cookiebox__content {
  display: flex;
  margin: auto auto;
  box-sizing: border-box;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding: 14px 30px 14px 30px;
}

#cookiebox .cookiebox__content svg {
  display: inline-block;
  margin-right: 10px;
}

#cookiebox .cookiebox__content a {
  color: #0a4c98;
  text-decoration: none;
  border-bottom: 1px solid #e8edf3;
}

#cookiebox .cookiebox__content button {
  appearance: none;
  border: 0;
  background: #08478c;
  color: #fff;
  outline: none;
  padding: 8px 17px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  margin-left: 15px;
}

#cookiebox .cookiebox__content button:hover {
  background: #2ecbea;
}

.more-button {
  transform: translateX(0);
  transition: transform 0.3s;
}

.more-button:hover {
  transform: translateX(5px);
  transition: transform 0.3s;
}

#slider-default video {
  max-width: 100%;
  width: 100%;
}

@media (max-width: 865px) {
  #slider-default video {
    max-width: none;
    width: auto;
    height: 450px;
  }
}

.menu-opened aside .navigation-mobile {
  top: 0;
  transition: top 0.3s ease-in;
}

.menu-opened aside #menu {
  background: #08478c;
}

.menu-opened aside #menu div span {
  background: #fff;
}

.menu-opened aside #menu div span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu-opened aside #menu div span:nth-child(2) {
  transform: rotate(45deg);
}

.menu-opened aside #menu div span:nth-child(3) {
  transform: rotate(-45deg);
}

.menu-opened aside #menu div span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu-opened aside .socials {
  margin: 0;
  width: auto;
  display: flex;
  list-style-type: none;
}

.menu-opened aside .socials li {
  flex: 1 1 25%;
  display: inline-block;
  border-bottom: 0;
  border-right: 1px solid #d2d2d2;
  padding: 0;
}

.menu-opened aside .socials li:last-of-type {
  border-right: 0;
}

.menu-opened aside .socials li:first-of-type {
  padding-left: 0;
}

.navigation-mobile {
  position: fixed;
  user-select: none;
  left: 0;
  right: 0;
  background: #08478c;
  height: 100vh;
  z-index: 105;
  display: block;
  width: 100%;
  top: 150%;
  transition: top 0.3s ease-out;
  padding: 100px 30px 0 30px;
}

.navigation-mobile .navigation-mobile-langs {
  display: none;
}

@media (max-width: 412px) {
  .navigation-mobile .navigation-mobile-langs {
    display: flex;
    position: absolute;
    top: 30px;
    left: 63px;
  }
  .navigation-mobile .navigation-mobile-langs img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 15px;
  }
}

.navigation-mobile li {
  display: block;
  margin-bottom: 10px;
  border-bottom: 1px solid #32659e;
  padding-bottom: 12px;
  padding-left: 35px;
  padding-top: 3px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.navigation-mobile li a {
  color: #fff;
  text-decoration: none;
  transition: all 0.2s;
  font-size: 20px;
  -webkit-tap-highlight-color: transparent;
}

.navigation-mobile li:hover a {
  color: #519bec;
  transition: all 0.2s;
}

.navigation-mobile li:last-of-type {
  margin-right: 0;
  border-bottom: 0;
}

@media (min-width: 1300px) {
  .navigation-mobile {
    display: none;
  }
}

.fontawesome-i2svg-complete .rowbox .heading-image .icon {
  opacity: 1;
  transition: opacity 0.2s;
}

.container {
  display: block;
  margin-right: 81px;
  clear: both;
  max-width: 1200px;
}

@media (max-width: 980px) {
  .container {
    margin-right: 31px;
  }
}

.navigation {
  list-style-type: none;
  float: right;
  margin-top: 9px;
}

.navigation li {
  display: inline-block;
  margin-right: 40px;
  position: relative;
  height: 47px;
  font-weight: 500;
}

.navigation li .navigation-more {
  display: none;
  width: auto;
  position: absolute;
  top: 100%;
  background: rgba(12, 54, 139, 0.9);
  left: 0;
  margin-left: -14px;
}

.navigation li .navigation-more a {
  white-space: nowrap;
  display: inline-block;
  color: #a5cbf5;
  border-bottom: 1px solid #0072e8;
  font-size: 14px;
  transition: color 0.2s;
  cursor: pointer;
  width: 100%;
}

.navigation li .navigation-more a span {
  color: #a5cbf5;
  transition: color 0.2s;
  height: auto;
  padding-top: 0;
  top: auto;
  margin: 13px 40px;
  display: block;
}

.navigation li .navigation-more a:first-of-type {
  padding-top: 4px;
}

.navigation li .navigation-more a:last-of-type {
  border: 0;
  /*padding-bottom: 22px;*/
}

.navigation li .navigation-more a:hover {
  color: #fff;
  transition: color 0.2s;
}

.navigation li .navigation-more a:hover a, .navigation li .navigation-more a:hover span {
  color: #fff;
  transition: color 0.2s;
}

.navigation li a {
  color: #58626c;
  text-decoration: none;
  transition: color 0.2s;
  font-size: 15px;
  display: inline-block;
}

.navigation li a.main-nav {
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px 0 47px 0;
  position: relative;
  top: -20px;
}

.navigation li.hoverable:hover:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 10px 7.5px;
  border-color: transparent transparent #08478c transparent;
  position: absolute;
  top: 100%;
  display: block;
  left: 30px;
  transform: translateX(-50%);
  margin-top: -9px;
}

.navigation li:hover a {
  color: #0b4790;
  transition: color 0.2s;
}

.navigation li:hover .navigation-more {
  display: block;
}

.navigation li.current a {
  color: #0b4790;
}

.navigation li:last-of-type {
  margin-right: 0;
}

@media (max-width: 1366px) {
  .navigation {
    margin-right: 20px;
  }
  .navigation li {
    margin-right: 20px;
  }
}

@media (max-width: 1200px) {
  .navigation {
    display: none;
  }
}

.selectable-trigger {
  border: 1px solid #e8edf3;
  box-sizing: border-box;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px 19px 0 19px;
  margin-left: 20px;
  position: relative;
  font-size: 14px;
  height: 34px;
  margin-top: -1px;
  white-space: nowrap;
}

.selectable-trigger svg {
  color: #08478c;
  display: inline-block;
  margin-left: 5px;
}

.selectable-trigger svg.hidden {
  display: none;
}

.selectable-trigger .trigger-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #e8edf3;
  border-top: 0;
  background: #fff;
  z-index: 1;
  margin-left: -1px;
  margin-right: -1px;
  display: none;
}

.selectable-trigger .trigger-options .option {
  padding: 7px 15px;
  border-top: 1px solid #e8edf3;
}

.selectable-trigger .trigger-options img {
  width: 16px;
  border: 0;
  float: right;
}

.selectable-trigger .trigger-options.opened {
  display: block;
}

@media (max-width: 480px) {
  .selectable-trigger {
    margin-top: 2px;
  }
}

@media (max-width: 412px) {
  .selectable-trigger {
    display: none;
  }
}

.button {
  appearance: none;
  cursor: pointer;
  border-radius: 0;
  outline: none;
  display: inline-block;
  user-select: none;
  text-transform: uppercase;
}

.button:focus, .button:hover {
  outline: none;
}

.button.loading {
  animation: flickerButton 1s infinite alternate;
  pointer-events: none;
}

@keyframes flickerButton {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.button.default {
  background-color: #08478c;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  height: 49px;
  box-sizing: border-box;
  padding: 0 34px 0 64px;
  position: relative;
  border: 0;
}

.button.default.product-ask {
  margin-top: 20px;
}

.button.default.transparent {
  background-color: transparent;
}

.button.default.transparent span {
  color: #2ecbea;
}

.button.default.banner {
  border: 1px solid #2ecbea;
  background: transparent;
  color: #fff;
}

.button.default.margined {
  margin-bottom: 12px;
}

.button.default span:before {
  content: "";
  width: 13px;
  height: 2px;
  background-color: #2ecbea;
  display: block;
  position: relative;
  top: 8px;
  left: -33px;
}

.button.default.right {
  float: right;
}

.button.default.right:after {
  content: "";
  clear: both;
  display: table;
}

.centered {
  display: flex;
  flex-direction: column;
}

.centered a {
  margin: 0 auto;
}

#menu {
  display: none;
  width: 0;
  transition: width 0.2s, height 0.2s;
}

@media (max-width: 1200px) {
  #menu {
    display: block;
    background: #fff;
    height: 84px;
    border: 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 106;
    width: 81px;
    padding: 0;
  }
  #menu div {
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    position: relative;
    display: block;
    width: 25px;
    height: 33.33333%;
    margin: auto auto;
    margin-top: 5px;
  }
  #menu div span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #08478c;
    border-radius: 0;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  #menu div span:nth-child(1) {
    top: 0px;
  }
  #menu div span:nth-child(2), #menu div span:nth-child(3) {
    top: 10px;
  }
  #menu div span:nth-child(4) {
    top: 20px;
  }
}

#search {
  background: #08478c;
  display: block;
  color: #fff;
  font-size: 18px;
  border: 0;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 81px;
  height: 84px;
  transition: width 0.2s, height 0.2s;
}

#search a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 1200px) {
  #search {
    display: none;
  }
}

#search svg {
  transition: all 0.2s;
}

#search:hover {
  background: #2ecbea;
}

#search:hover svg {
  transform: scale(1.3);
  transition: all 0.2s;
}

.scrolling #search {
  width: 61px;
  height: 64px;
  transition: width 0.2s, height 0.2s;
}

.scrolling nav {
  padding-top: 15px;
  transition: padding 0.2s;
}

.scrolling .navbar {
  height: 64px;
  transition: height 0.2s;
}

.scrolling .navigation li.hoverable:hover:before {
  margin-top: -16px;
}

.scrolling .navigation li .navigation-more {
  top: 40px;
}

.scrolling #menu {
  /*width: 61px;*/
  height: 64px;
  transition: width 0.2s, height 0.2s;
}

@media (max-width: 1200px) {
  .scrolling #menu div {
    top: -3px;
  }
}

li,
ul {
  list-style-type: none;
}

.banner {
  height: 305px;
  background-color: #09488d;
  position: relative;
  overflow: hidden;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.banner .description {
  color: #fff;
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 6px;
}

.banner .description h1 {
  font-size: 46px;
  margin: 0;
  letter-spacing: 3px;
  opacity: 0;
  animation: showTitlemain 0.3s;
  animation-fill-mode: forwards;
}

@keyframes showTitlemain {
  100% {
    opacity: 1;
  }
}

@media (max-width: 980px) {
  .banner .description {
    left: 31px;
    right: 31px;
  }
  .banner .description h1 {
    font-size: 30px;
  }
}

.banner .description .breadcrumb {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #c3cdd9;
  margin-top: 5px;
}

.banner .description .breadcrumb i {
  font-size: 18px;
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 3px;
}

.banner .description .breadcrumb i:last-of-type {
  display: none;
}

.banner .description .breadcrumb a {
  text-decoration: none;
  color: #c3cdd9;
}

.banner h3 {
  position: absolute;
  right: 66px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-top: 12px;
  user-select: none;
  transition: right 0.3s;
}

.banner h3:hover {
  right: 61px;
  transition: right 0.3s;
}

.banner h3:before {
  content: "";
  background-color: #2ec4e9;
  width: 13px;
  height: 1px;
  display: block;
  position: absolute;
  margin-left: -33px;
  top: 6px;
}

.banner h3 a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 980px) {
  .banner h3 {
    transform: none;
    top: auto;
    right: auto;
    left: 61px;
    bottom: 66px;
  }
}

.slider-smaller .anythingSlider-default .anythingControls ul {
  position: absolute;
  top: 100%;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  right: auto;
  margin-top: 23px;
}

.slider-smaller .anythingSlider-default .anythingControls ul a {
  color: #58626c;
}

.slider-smaller .anythingSlider-default .anythingControls ul a.cur {
  color: #0a4c98;
  border-bottom: 1px solid #2ecbea;
}

.slider-smaller .anythingSlider-default .anythingControls ul li:last-of-type a {
  margin-right: 0;
}

.anythingSlider-default {
  margin: 0 auto;
  padding: 0;
}

.anythingSlider-default.loaded {
  opacity: 1;
}

.anythingSlider-default.loaded #slider {
  opacity: 1;
}

.anythingSlider-default .arrow {
  display: none !important;
}

.anythingSlider-default #slider {
  width: 100%;
  height: 570px;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.anythingSlider-default #slider.smaller {
  height: 409px;
  margin-bottom: 0;
}

.anythingSlider-default #slider-miniature {
  width: 100%;
  height: 570px;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.anythingSlider-default #slider-miniature.smaller {
  height: 409px;
  margin-bottom: 0;
}

.anythingSlider-default .anythingControls {
  outline: 0;
  display: none;
}

.anythingSlider-default .anythingControls ul {
  margin: 0;
  padding: 0;
  position: absolute;
  right: 30px;
  bottom: 30px;
  user-select: none;
}

.anythingSlider-default .anythingControls ul li {
  display: inline;
  margin: 0 34px 0 0;
}

.anythingSlider-default .anythingControls ul li.last {
  margin-right: 0;
}

.anythingSlider-default .anythingControls ul a {
  display: inline-block;
  text-decoration: none;
  padding: 0;
  height: 18px;
  text-align: center;
  outline: 0;
  font-weight: 600;
  letter-spacing: 1px;
}

.anythingSlider-default .anythingControls a {
  background: transparent;
  color: #949494;
  border-radius: 0;
  font-size: 13px;
}

.anythingSlider-default .anythingControls a span {
  visibility: visible;
}

.anythingSlider-default .anythingControls a.cur {
  color: #fff;
}

.anythingSlider-default .anythingControls .anythingNavWindow {
  overflow: hidden;
  float: left;
}

.anythingSlider {
  display: block;
  overflow: visible !important;
  position: relative;
  margin-bottom: 70px;
}

.anythingSlider .anythingWindow {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.anythingSlider .anythingBase {
  background: transparent;
  list-style: none;
  position: absolute;
  overflow: visible !important;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.anythingSlider .panel {
  background: transparent;
  display: block;
  overflow: hidden;
  float: left;
  padding: 0;
  margin: 0;
}

.anythingSlider .panel img {
  height: 409px;
}

.anythingSlider .fade .panel {
  float: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.anythingSlider .fade .activePage {
  z-index: 1;
}

.anythingSlider.rtl .anythingWindow {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.anythingSlider,
.anythingSlider .anythingControls ul a,
.anythingSlider .anythingWindow,
.anythingSlider .arrow a,
.anythingSlider .start-stop {
  transition-duration: 0s;
}

h3.page-title {
  font-weight: 500;
  font-style: normal;
  font-size: 38px;
  color: #0a4c98;
  margin-top: 0;
}

h3.page-title.center {
  display: block;
  width: 100%;
  text-align: center;
}

h3.page-title.gray {
  color: #868585;
  font-weight: 500;
  font-size: 36px;
}

h3.page-title.smaller {
  font-size: 33px;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 50px;
}

h3.page-title.smaller.nomargin {
  margin-bottom: 10px;
}

.blue h3.page-title {
  color: #fff;
}

@media (max-width: 980px) {
  .blue h3.page-title {
    margin: 0;
    margin-bottom: 41px;
  }
}

.map-container {
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;
  height: 500px;
}

.map-container #map {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.map-container .map-info {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  background: #08478c;
  color: #fff;
  padding: 36px 42px 36px 43px;
  font-size: 17px;
  width: calc(50% - 50px);
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .map-container .map-info {
    left: 0;
    top: 0;
    bottom: 4px;
    transform: none;
  }
}

.map-container .map-info b {
  display: block;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.map-container .map-info p {
  margin-top: 10px;
  margin-bottom: 34px;
}

.map-container .map-info a {
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
}

.map-container .map-info a svg {
  font-size: 19px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

@media (max-width: 1050px) {
  .map-container {
    height: auto;
  }
  .map-container #map {
    position: relative;
    height: 300px;
  }
  .map-container .map-info {
    position: relative;
    margin-top: 50px;
    width: 100%;
  }
}

.inline-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 70px;
  align-items: stretch;
  width: 100%;
}

.inline-grid.more-border {
  margin-left: -7px;
  margin-right: -7px;
}

.inline-grid.more-border .default-icon {
  margin-right: 5px;
}

.inline-grid .row {
  flex: auto;
  width: 25%;
  box-sizing: border-box;
  flex-grow: 0;
}

.inline-grid .row.row3 {
  width: 57%;
}

.inline-grid .row.row3.nopadding {
  padding-left: 0;
}

.inline-grid .row.row3.paddingright {
  padding-right: 100px;
}

@media (max-width: 980px) {
  .inline-grid .row.row3.paddingright {
    padding-right: 0;
  }
}

.inline-grid .row.row3.paddingleft {
  padding-left: 50px;
}

@media (max-width: 980px) {
  .inline-grid .row.row3.paddingleft {
    padding-left: 0;
  }
}

.inline-grid .row.rowfour {
  padding: 0 11px;
  margin-bottom: 28px;
}

@media (max-width: 1250px) {
  .inline-grid .row.rowfour {
    flex: 0 1 33.33333%;
  }
}

@media (max-width: 880px) {
  .inline-grid .row.rowfour {
    flex: 0 1 50%;
  }
}

@media (max-width: 600px) {
  .inline-grid .row.rowfour {
    flex: 0 1 100%;
  }
}

@media (max-width: 480px) {
  .inline-grid .row.rowfour {
    padding: 0;
  }
}

.inline-grid .row.row2 {
  width: 43%;
  box-sizing: border-box;
}

.inline-grid .row.row2 img {
  max-width: 100%;
}

.inline-grid .row.row2.bigpadding {
  padding-left: 150px;
  padding-right: 0;
}

.inline-grid .row.rowthree {
  border: 0;
  margin-bottom: 28px;
  box-sizing: border-box;
  padding: 0 11px;
  width: 33.33333%;
}

.inline-grid .row.rowthree a {
  padding: 7px;
  box-sizing: border-box;
}

.inline-grid .row.rowthree.nopadding {
  padding-left: 0;
}

.inline-grid .row.rowthree.no-margin-bottom {
  margin-bottom: 0;
}

.inline-grid .row.rowthree.margined {
  padding: 0 13px;
}

@media (max-width: 480px) {
  .inline-grid .row.rowthree.margined {
    padding: 0;
  }
}

@media (max-width: 980px) {
  .inline-grid .row.rowthree {
    width: 50%;
  }
  .inline-grid .row.rowthree.nopadding {
    padding-bottom: 0;
  }
}

@media (max-width: 680px) {
  .inline-grid .row.rowthree {
    width: 100%;
    padding: 0;
  }
}

.inline-grid .row.rowfive {
  flex: 0 1 30%;
  padding-left: 0;
}

@media (max-width: 980px) {
  .inline-grid .row.rowfive {
    flex: 0 1 100%;
  }
}

.inline-grid .row.rowhalf {
  padding: 0 13px;
  overflow: hidden;
  margin-bottom: 28px;
  box-sizing: border-box;
  flex: auto;
  width: 50%;
}

.inline-grid .row.rowhalf .rowbox .controll {
  margin: 0 28px 21px;
}

.inline-grid .row.rowhalf .rowbox .controll a.more-button i {
  top: 13px;
}

.inline-grid .row.rowhalf.paddingright {
  padding-right: 30px;
}

.inline-grid .row.rowhalf.nopadding {
  padding-right: 0;
}

.inline-grid .row.rowhalf.margined {
  padding: 0 13px;
}

@media (max-width: 480px) {
  .inline-grid .row.rowhalf.margined {
    padding: 0;
  }
}

@media (max-width: 880px) {
  .inline-grid .row.rowhalf.rowhalf {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .inline-grid .row.rowhalf.rowhalf {
    padding: 0;
  }
}

.inline-grid .row .details {
  display: inline-block;
  margin-left: 9px;
  max-width: 190px;
  line-height: 23px;
  cursor: default;
  user-select: text;
}

.inline-grid .row .details b {
  display: block;
  color: #0a4c98;
  margin-bottom: 2px;
}

.inline-grid .row svg {
  color: #31caec;
  font-size: 30px;
  vertical-align: top;
}

.inline-grid .row.blued {
  height: 300px;
  padding: 0 11px !important;
  box-sizing: border-box;
  margin-bottom: 28px;
}

@media (max-width: 480px) {
  .inline-grid .row.blued {
    padding: 0 !important;
  }
}

@media (max-width: 800px) {
  .inline-grid.responsive-nomargin .row {
    padding-bottom: 0;
  }
}

.inline-grid.small-margin {
  margin-bottom: 13px;
}

.inline-grid.half-margin {
  margin-bottom: 30px;
}

.inline-grid.fixmargin {
  margin-left: -13px;
  margin-right: -13px;
  width: auto;
  min-width: 100%;
}

@media (max-width: 480px) {
  .inline-grid.fixmargin {
    margin: 0;
  }
}

@media (max-width: 680px) {
  .inline-grid.fixmargin2 {
    margin-left: 0;
    margin-right: 0;
  }
}

.inline-grid.nodecoration a {
  text-decoration: none;
}

.inline-grid.link .quote-image {
  cursor: pointer;
}

.inline-grid.link label {
  cursor: pointer;
}

.inline-grid.listed .listed-icon {
  font-size: 55px;
  margin-bottom: 18px;
}

.inline-grid.table .row {
  padding: 0;
  color: #94989b;
  font-weight: 500;
}

.inline-grid.table .row.noborder {
  border: 0;
}

.inline-grid.table .row.nomargin {
  margin-bottom: 0;
  margin-right: 0;
}

.inline-grid.table .row a {
  text-decoration: none;
  color: #0a4c98;
  user-select: none;
}

.inline-grid.table .row a.download {
  display: block;
  margin: 7px 0 6px;
  font-weight: 500;
}

.inline-grid.table .row a.download svg {
  display: inline-block;
  margin-right: 11px;
  font-size: 25px;
}

.inline-grid.table .row a.download .additional {
  color: #58626c;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
}

.inline-grid.table .row .rowbox {
  margin: 0;
  padding: 17px 33px 14px;
  box-sizing: border-box;
}

.inline-grid.table .row .rowbox.nopadding {
  padding: 0;
}

.inline-grid.table .row .rowbox.blued-hover {
  height: 300px;
  padding: 0;
  background-position: center center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: relative;
}

.inline-grid.table .row .rowbox.blued-hover .bluedbox {
  background-color: rgba(9, 72, 141, 0.9);
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: auto;
}

.inline-grid.table .row .rowbox.blued-hover .bluedbox b {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin: 10px 14px;
}

.inline-grid.table .row .rowbox.blued-hover .bluedbox .button {
  display: none;
}

.inline-grid.table .row .rowbox.blued-hover:hover .bluedbox {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-align: center;
}

.inline-grid.table .row .rowbox.blued-hover:hover .bluedbox .box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inline-grid.table .row .rowbox.blued-hover:hover .bluedbox .button {
  display: block;
  margin: 5px auto 0;
  background-color: transparent;
  border: 2px solid #2ecbea;
  white-space: nowrap;
}

.inline-grid.table .row.heading {
  background-color: #165eb2;
  color: #fff;
  box-sizing: border-box;
  font-weight: 500;
}

.inline-grid.table .row.heading .rowbox {
  border-color: #165eb2;
}

.inline-grid.table .row.heading:first-of-type {
  background-color: #0a4c96;
}

.inline-grid.table .row.heading:first-of-type .rowbox {
  border-color: #0a4c96;
}

.inline-grid.table .row.heading:last-of-type {
  background-color: #2471c9;
}

.inline-grid.table .row.heading:last-of-type .rowbox {
  border-color: #2471c9;
}

.inline-grid.table .row.white {
  background-color: #fff;
}

.inline-grid.marginbottom .row {
  margin-bottom: 50px;
}

@media (max-width: 1300px) {
  .inline-grid .row {
    padding-left: 20px;
  }
  .inline-grid .row .details {
    display: block;
    margin-left: 0;
  }
  .inline-grid .row svg {
    margin-bottom: 20px;
  }
}

@media (max-width: 1200px) {
  .inline-grid .row {
    width: calc(100% / 3);
  }
}

@media (max-width: 980px) {
  .inline-grid .row {
    width: 50%;
    padding-bottom: 30px;
  }
  .inline-grid .row.row2 {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .inline-grid .row.row3 {
    width: 100%;
    padding-left: 0;
  }
  .inline-grid .row.row3.contact-responsive-margin {
    padding-bottom: 0;
  }
}

@media (max-width: 600px) {
  .inline-grid .row {
    width: 100%;
    padding-left: 0;
  }
  .inline-grid .row .inlined .step-arrow {
    display: none;
  }
}

.list .svglist svg {
  font-size: 20px;
  margin-right: 18px;
}

.page-section {
  margin-top: 0;
  margin-bottom: 50px;
}

@media (max-width: 480px) {
  .page-section {
    margin-bottom: 41px;
  }
}

.page-section.fix-margin-bottom {
  margin-bottom: 20px;
}

.page-section.last-section {
  margin-bottom: 71px;
}

.page-section.custom1 {
  margin-bottom: 75px;
}

.page-section.custom2 {
  margin-bottom: 78px;
}

.page-section.bordered {
  border-top: 1px solid #dfe3e6;
}

.page-section.bordered .page-title {
  margin-top: 50px;
}

.page-section.imaged {
  background-size: 1204px auto;
  background-repeat: no-repeat;
}

@media (max-width: 1100px) {
  .page-section.imaged {
    background-size: auto 100%;
    background-position: center center;
  }
}

.page-section.single-quote-image a {
  display: block;
  text-decoration: none;
}

.page-section.single-quote-image label {
  cursor: pointer;
}

.page-section.single-quote-image .quote-image {
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
}

.page-section.carousel-arrows {
  position: relative;
}

.page-section.carousel-arrows .carouselarrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  left: 0;
  right: 0;
  margin-top: 20px;
  display: none;
}

.page-section.carousel-arrows .carouselarrows button {
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
  color: #94989b;
  font-size: 22px;
  outline: none;
  padding: 15px;
}

.page-section.carousel-arrows .carouselarrows button:focus, .page-section.carousel-arrows .carouselarrows button:hover {
  outline: none;
}

.page-section.carousel-arrows .carouselarrows button:hover {
  color: #0a4c98;
  transform: scale(1.5);
  transition: transform 0.2s;
}

.page-section.carousel-arrows .carouselarrows button#right {
  float: right;
  margin-right: -50px;
}

.page-section.carousel-arrows .carouselarrows button#left {
  float: left;
  margin-left: -50px;
}

.page-section fieldset {
  padding: 17px 0;
  box-sizing: border-box;
  display: block;
  margin-top: 3px;
}

.page-section fieldset ul {
  padding-inline-start: 30px;
}

.page-section fieldset ul li {
  margin-bottom: 20px;
}

.page-section fieldset ul li:last-of-type {
  margin-bottom: 0;
}

.page-section fieldset.list {
  padding: 30px 22px;
}

.page-section fieldset.list ul, .page-section fieldset.list li {
  list-style-type: disc;
}

.page-section fieldset.list ul {
  margin: 0;
  padding-top: 15px;
}

.page-section fieldset.list.white-border {
  border: 1px solid #fff;
}

.page-section fieldset.nopadding {
  padding-top: 0;
}

.page-section fieldset.job {
  border: 1px solid #efefef;
  margin: 0 auto 60px auto;
  display: block;
  width: 70%;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0;
}

@media (max-width: 980px) {
  .page-section fieldset.job {
    width: 100%;
  }
}

.page-section fieldset.job ul {
  display: flex;
  padding-inline-start: 0;
  margin-block-start: 3px;
  margin-block-end: 0;
  justify-content: center;
  list-style-type: none;
}

.page-section fieldset.job ul li {
  flex: 0 1 33.3333%;
  text-align: center;
  list-style-type: none;
}

@media (max-width: 780px) {
  .page-section fieldset.job ul {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .page-section fieldset.job ul li {
    flex: 0 1 50%;
  }
  .page-section fieldset.job ul li:last-of-type {
    margin-bottom: 20px;
  }
}

@media (max-width: 580px) {
  .page-section fieldset.job ul li {
    flex: 0 1 100%;
  }
}

.page-section .timeline {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 242px;
  align-items: center;
}

.page-section .timeline .year {
  flex: 0 1 20%;
  border-right: 2px solid #e6e6e6;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  padding: 40px 0;
}

.page-section .timeline .year .infos {
  display: none;
}

.page-section .timeline .year button {
  display: none;
}

.page-section .timeline .year i {
  display: block;
  width: 100%;
  height: 18px;
}

.page-section .timeline .year i:before {
  content: "";
  width: 1px;
  height: 18px;
  background-color: #e6e6e6;
  position: absolute;
  left: calc(100% / 3);
}

.page-section .timeline .year i:after {
  content: "";
  width: 1px;
  height: 18px;
  background-color: #e6e6e6;
  position: absolute;
  left: 66.6666%;
}

.page-section .timeline .year b {
  display: block;
  width: 100%;
  height: 18px;
}

.page-section .timeline .year b span {
  position: relative;
  font-size: 21px;
  font-weight: 500;
  top: 46px;
  user-select: none;
  letter-spacing: 1px;
}

.page-section .timeline .year b:before {
  content: "";
  width: 1px;
  height: 18px;
  background-color: #e6e6e6;
  position: absolute;
  left: calc(100% / 3);
}

.page-section .timeline .year b:after {
  content: "";
  width: 1px;
  height: 18px;
  background-color: #e6e6e6;
  position: absolute;
  left: 66.6666%;
}

.page-section .timeline .year img {
  width: 100%;
}

.page-section .timeline .year:last-of-type {
  border-right: 0;
}

.page-section .timeline .year.current {
  flex: 0 1 44%;
  max-height: 312px;
  border: 14px solid #e6e6e6;
  position: relative;
  left: -2px;
  top: 11px;
  padding-top: 0;
  padding-bottom: 0;
}

.page-section .timeline .year.current button {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: #124f8e;
  appearance: none;
  border-radius: 0;
  width: 45px;
  height: 100px;
  text-align: center;
  color: #fff;
  z-index: 10;
  border: 0;
}

.page-section .timeline .year.current button:focus, .page-section .timeline .year.current button:hover {
  outline: none;
}

.page-section .timeline .year.current button svg {
  font-size: 25px;
}

.page-section .timeline .year.current button.go-left {
  left: 0;
  margin-left: -32px;
}

.page-section .timeline .year.current button.go-right {
  right: 0;
  margin-right: -32px;
}

.page-section .timeline .year.current .infos {
  display: block;
  margin-top: 59px;
  line-height: 25px;
}

.page-section .timeline .year.current .infos h4 {
  margin: 0 0 18px;
  font-size: 30px;
  color: #0a4c98;
  letter-spacing: 1px;
  user-select: none;
}

.page-section .timeline .year.current b,
.page-section .timeline .year.current i {
  display: none;
}

.page-section .quote-image {
  background-size: 100% auto;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
}

.page-section .quote-image label {
  display: block;
  width: 70%;
  margin: 0 auto;
  padding: 200px 0;
  color: #fff;
  z-index: 2;
  position: relative;
  text-align: center;
  font-size: 20px;
  font-style: italic;
}

.page-section .quote-image label p {
  line-height: 34px;
}

.page-section .quote-image.colored label {
  color: #0a4c98;
}

.page-section .quote-image .transparent-back {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 55, 115, 0.7);
}

@media (max-width: 980px) {
  .page-section .quote-image label {
    padding: 100px 0;
  }
}

.page-section .quote-image.small {
  text-decoration: none;
}

.page-section .quote-image.small:first-of-type {
  margin-right: 3.5px;
}

.page-section .quote-image.small:last-of-type {
  margin-left: 3.5px;
}

.page-section .quote-image.small label {
  font-style: normal;
  font-size: 30px;
  letter-spacing: 1px;
  padding: 135px 0;
  font-weight: normal;
  text-decoration: none;
  user-select: none;
}

.page-section .quote-image.small label .additional {
  display: block;
  font-size: 14px;
  margin-top: 5px;
  letter-spacing: 1px;
  color: #2ecbea;
}

@media (max-width: 880px) {
  .page-section .quote-image.small {
    background-size: 100% auto;
  }
}

@media (max-width: 680px) {
  .page-section .quote-image.small {
    background-size: auto 100%;
  }
}

.page-section .quote-image.fit label {
  padding: 0;
}

.page-section .owl-carousel {
  display: block;
  margin-top: 55px;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.page-section .owl-carousel.loaded {
  opacity: 1;
}

.page-section .owl-carousel .owl-nav button {
  font-size: 50px;
}

.page-section .owl-carousel .owl-nav .owl-prev {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.page-section .owl-carousel .owl-nav .owl-next {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.page-section .owl-carousel.carousel-border .owl-item {
  border: 1px solid #ebebeb;
  box-sizing: border-box;
}

@media (hover: hover) {
  .page-section .owl-carousel.carousel-border .owl-item:hover {
    border-color: #7b95c0;
  }
}

.page-section .owl-carousel.carousel-border .owl-item .custom-item {
  margin: 31px 31px 21px;
  text-align: center;
  height: 300px;
}

.page-section .owl-carousel.carousel-border .owl-item .custom-item a {
  text-decoration: none;
}

.page-section .owl-carousel.carousel-border .owl-item .custom-item label {
  color: #0a4c98;
  font-weight: 600;
  display: block;
  margin-top: 13px;
  line-height: 22px;
}

.page-section .owl-carousel.carousel-border .owl-item .custom-item.more-margin {
  margin: 25px 25px 21px;
  height: 240px;
}

@media (max-width: 500px) {
  .page-section .owl-carousel.carousel-border .owl-item .custom-item.more-margin {
    height: auto;
  }
}

.page-section .owl-carousel.carousel-border .owl-item .custom-item.product .additional {
  color: #58626c;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .page-section .owl-carousel.carousel-border .owl-item .custom-item img {
    max-width: 200px;
    display: block;
    margin: auto auto;
  }
}

.page-section .owl-carousel .owl-dots {
  text-align: center;
  margin-top: 31px;
  font-size: 13px;
}

.page-section .owl-carousel .owl-dots button {
  outline: none;
  margin: 0 15px 10px 15px;
  /*@media (min-width: 480px) {
          &:last-of-type {
            margin-right: 0;
          }
        }*/
}

.page-section .owl-carousel .owl-dots button:focus, .page-section .owl-carousel .owl-dots button:hover {
  outline: none;
}

@media (max-width: 480px) {
  .page-section .owl-carousel .owl-dots button {
    margin-bottom: 10px;
  }
}

.page-section .owl-carousel .owl-dots .owl-dot span {
  letter-spacing: 1px;
  display: block;
  backface-visibility: visible;
  transition: opacity 0.2s ease;
  height: 18px;
  font-weight: 600;
  border-bottom: 1px solid transparent;
}

.page-section .owl-carousel .owl-dots .owl-dot.active span {
  color: #0a4c98;
  border-bottom: 1px solid #2ecbea;
}

@media (max-width: 480px) {
  .page-section .owl-carousel .owl-dots {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.page-section.blue {
  background-color: #124f8e;
  color: #6b9edd;
  padding: 47px 81px;
  box-sizing: border-box;
}

.page-section.blue fieldset {
  border: 1px solid #2966a9;
  color: #fff;
}

.page-section.blue fieldset ul {
  padding-top: 0;
}

.page-section.blue fieldset ul, .page-section.blue fieldset li {
  list-style-type: disc;
}

.page-section.blue .tabs__more .banner {
  margin-bottom: 18px;
}

.page-section.blue p {
  color: #6b9edd;
}

.page-section.blue p.colored {
  color: #fff;
}

.page-section.blue .owl-carousel .owl-stage-outer {
  border-left: 1px solid #124f8e;
  border-right: 1px solid #124f8e;
}

.page-section.blue .owl-carousel .owl-dots .owl-dot.active span {
  color: #fff;
}

.page-section.blue .inline-grid.listed .row svg {
  color: #6b9edd;
}

.page-section.blue .inline-grid.listed .row svg.step-icon {
  position: relative;
  right: 10px;
}

@media (max-width: 480px) {
  .page-section.blue .inline-grid.listed .row svg.step-icon {
    right: auto;
  }
}

.page-section.blue .inline-grid.listed .row b {
  color: #fff;
  display: block;
  font-size: 17px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

@media (max-width: 980px) {
  .page-section.blue {
    margin-left: -31px;
    margin-right: -31px;
    overflow: hidden;
    padding: 50px 31px;
  }
}

.page-section p {
  line-height: 23px;
  margin: 0;
  color: #58626c;
  font-weight: 300;
}

.page-section p.clause {
  display: block;
  margin-top: 50px;
  font-style: italic;
  color: #94989b;
  margin-bottom: 63px;
}

.page-section p.margintop {
  margin-top: 43px;
}

.text-input {
  appearance: none;
  border-radius: 0;
  border: 1px solid #e8edf3;
  display: block;
  outline: none;
  height: 50px;
  padding: 0 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.text-input:focus, .text-input:hover {
  outline: none;
}

.text-input.textarea {
  height: 239px;
  resize: none;
  padding-top: 15px;
  padding-bottom: 15px;
}

.text-input.textarea.smaller {
  height: 114px;
}

.text-input.textarea.contact {
  height: 254px;
}

.text-input.error {
  border-color: #08478c;
  animation: hideErrorEffect 1s 0.2s;
  animation-fill-mode: forwards;
}

form .text-input {
  margin-bottom: 24px;
}

label {
  color: #55626b;
  display: block;
  margin-bottom: 10px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

html.lb-disable-scrolling {
  overflow: hidden;
  /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox a img {
  border: none;
}

.lightbox .lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lightbox .lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  border: 4px solid white;
}

.lightbox .lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: white;
}

.lightbox .lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lightbox .lb-outerContainer .lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lightbox .lb-outerContainer .lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("../images/lightbox/loading.gif") no-repeat;
}

.lightbox .lb-outerContainer .lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lightbox .lb-outerContainer .lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lightbox .lb-outerContainer .lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("../images/lightbox/prev.png") left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lightbox .lb-outerContainer .lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("../images/lightbox/next.png") right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lightbox .lb-outerContainer .lb-nav .lb-next,
.lightbox .lb-outerContainer .lb-nav .lb-prev {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lightbox .lb-outerContainer .lb-container > .nav {
  left: 0;
}

.lightbox .lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lightbox .lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lightbox .lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url("../images/lightbox/close.png") top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lightbox .lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lightbox .lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lightbox .lb-data .lb-caption a {
  color: #4ae;
}

.text-center {
  text-align: center;
}

.slider-miniatures .anythingControls {
  position: relative;
}

.slider-miniatures .anythingControls ul {
  right: 0;
  top: 100%;
  margin-top: 40px;
  position: relative;
  display: block;
}

.slider-miniatures .anythingControls ul li {
  width: 25%;
  display: inline-block;
  margin: 0;
  padding: 0 5px;
  box-sizing: border-box;
}

.slider-miniatures .anythingControls ul li a {
  display: block;
  width: 100%;
}

.slider-miniatures .anythingControls ul li .thumb {
  width: 100%;
  height: 100px;
  background-size: 150px auto;
  background-repeat: no-repeat;
  background-color: #000;
}

.slider-miniatures .anythingControls ul li:first-of-type {
  padding-left: 0;
}

.slider-miniatures .anythingControls ul li:last-of-type {
  padding-right: 0;
}

.slider-miniatures .anythingControls ul li img {
  height: 100px;
}

.or {
  text-align: center;
  font-weight: 500;
  color: #bbbbbb;
  flex: 0 1 5%;
}

@media (max-width: 980px) {
  .or {
    flex: 0 1 100%;
    padding-bottom: 55px;
  }
}

.rowbox {
  width: auto;
  border: 1px solid #ebeff2;
  display: block;
  margin-bottom: 24px;
  line-height: 25px;
  height: 100%;
  box-sizing: border-box;
}

@media (hover: hover) {
  .rowbox:hover {
    border-color: #7b95c0;
  }
}

@media (hover: hover) {
  .rowbox.nohover:hover {
    border-color: #ebeff2;
  }
}

.rowbox a {
  text-decoration: none;
  display: block;
}

.rowbox.nomargin {
  margin-bottom: 0;
}

.rowbox.nomargin .additional {
  margin-bottom: 0;
}

.rowbox .additional {
  color: #58626c;
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 30px;
}

.rowbox .heading-image {
  height: 240px;
  display: block;
  overflow: hidden;
  background-color: #ffffff;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-position: center 30px;
  margin-bottom: 34px;
  position: relative;
}

.rowbox .heading-image.full {
  margin: 25px;
  background-position: center center;
  background-size: auto 100%;
  transition: background-size 0.2s ease-in;
}

.rowbox .heading-image.full.nomargin {
  margin-bottom: 0;
}

.rowbox .heading-image.cover {
  background-size: cover;
}

.rowbox .heading-image.width {
  background-size: 100% auto;
  transition: background-size 0.2s ease-in;
}

.rowbox .heading-image .icon {
  background-color: #08478c;
  height: 55px;
  width: 55px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: center;
  position: relative;
}

.rowbox .heading-image .icon svg {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
}

.rowbox.job {
  padding: 50px 0 62px;
}

.rowbox.job img {
  border: 0;
  max-width: 60px;
}

.rowbox.job b {
  margin: 25px 25px 0 25px;
}

.rowbox b {
  color: #0a4c98;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  display: block;
  /*margin: 0 36px 12px;*/
  margin: 0 24px 12px;
}

.rowbox b.nomargin {
  margin-bottom: 0;
}

@media (max-width: 480px) {
  .rowbox b {
    white-space: normal;
  }
}

.rowbox label {
  margin: 0 36px 26px;
}

.rowbox .controll {
  display: block;
  margin: 0 36px 21px;
  font-size: 13px;
}

.rowbox .controll .time {
  float: right;
  font-weight: 500;
  letter-spacing: 1px;
  color: #989eaa;
  user-select: none;
  margin-top: 7px;
}

.rowbox .controll a {
  color: #2ec4e9;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  margin-left: 23px;
  position: relative;
  display: inline-block;
}

.rowbox .controll a i {
  background-color: #2ec4e9;
  left: -23px;
  width: 15px;
  height: 2px;
  display: block;
  position: absolute;
  top: 18px;
}

.rowbox .controll a.more-button {
  color: #08478c;
  padding-left: 7px;
}

.rowbox .controll a.more-button i {
  height: 1px;
}

.rowbox .controll.small-margin {
  margin-left: 14px;
}

.rowbox:hover .heading-image.full {
  background-size: auto 110%;
  transition: background-size 0.2s ease-in;
}

.rowbox:hover .heading-image.full.width {
  background-size: 110% auto;
  transition: background-size 0.2s ease-in;
}

.pointer .rowbox:hover {
  border-color: #7b95c0;
}

.paginate {
  display: block;
  list-style-type: none;
  text-align: center;
  user-select: none;
  color: #58626c;
  font-size: 13px;
  cursor: default;
}

.paginate li {
  display: inline-block;
  text-decoration: none;
  padding: 0;
  height: 18px;
  text-align: center;
  outline: 0;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 34px 0 0;
  cursor: pointer;
}

.paginate li.current {
  color: #0a4c98;
  border-bottom: 1px solid #2ecbea;
}

.rotator.hidden-rotator {
  display: none;
}

.intro-boxes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-left: -10px;
  margin-right: -10px;
}

.intro-boxes .box {
  position: relative;
  border: 1px solid #e8edf3;
  box-sizing: border-box;
  margin: 0 10px 20px 10px;
  display: inline-block;
  background-color: #fff;
  height: 360px;
  padding: 2px 39px;
}

.intro-boxes .box p {
  color: inherit;
  font-weight: 300;
  font-size: 15px;
}

.intro-boxes .box iframe {
  border: 0;
  width: 100%;
}

.intro-boxes .box a {
  text-decoration: none;
}

.intro-boxes .box .button-box {
  text-decoration: none;
  color: #08478c;
  outline: none;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  text-transform: uppercase;
  position: relative;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 3px;
  padding: 10px 15px 10px 30px;
  width: 70px;
}

.intro-boxes .box .button-box span:before {
  content: '';
  background-color: #2ec4e9;
  height: 1px;
  display: block;
  position: absolute;
  margin-left: -28px;
  top: 16px;
  width: 13px;
}

.intro-boxes .box h4 {
  font-size: 26px;
  color: #08478c;
  font-weight: 400;
  text-decoration: none;
}

.intro-boxes .box.box1 {
  width: calc(54% - 20px);
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
}

.intro-boxes .box.box1:hover .play {
  background-image: url("../images/ico_play_h.png");
}

.intro-boxes .box.box1 iframe {
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  height: 101%;
  width: 101%;
}

.intro-boxes .box.box1.show-movie .preview {
  display: none;
}

.intro-boxes .box.box1.show-movie .play {
  display: none;
}

.intro-boxes .box.box1.show-movie iframe {
  opacity: 1;
  transition: opacity 0.3s;
}

@media (max-width: 1115px) {
  .intro-boxes .box.box1 {
    width: calc(100% - 20px);
    margin-bottom: 50px;
  }
}

.intro-boxes .box.box2 {
  width: calc(46% - 20px);
  margin-bottom: 50px;
}

.intro-boxes .box.box2 .content {
  font-size: 15px;
  font-weight: 300;
  line-height: 23px;
}

@media (max-width: 1115px) {
  .intro-boxes .box.box2 {
    width: calc(50% - 20px);
    margin-bottom: 50px;
    height: 453px;
  }
}

@media (max-width: 980px) {
  .intro-boxes .box.box2 {
    height: auto;
  }
}

.intro-boxes .box.box3 {
  width: calc(28% - 20px);
  background-color: #e1e8f0;
  padding: 12px 10px 20px 10px;
  height: 453px;
  position: relative;
  text-align: center;
  margin-bottom: 0;
}

@media (max-width: 1115px) {
  .intro-boxes .box.box3 {
    width: calc(50% - 20px);
    margin-bottom: 50px;
  }
}

@media (max-width: 980px) {
  .intro-boxes .box.box3 {
    height: auto;
  }
}

.intro-boxes .box.box4 {
  width: calc(72% - 20px);
  overflow: hidden;
  padding: 12px 47px 20px 47px;
  height: 453px;
  margin-bottom: 0;
}

@media (max-width: 1115px) {
  .intro-boxes .box.box4 {
    width: calc(100% - 20px);
  }
}

@media (max-width: 980px) {
  .intro-boxes .box {
    width: calc(100% - 20px) !important;
    margin-bottom: 59px;
  }
  .intro-boxes .box.box3 {
    margin-bottom: 59px;
  }
}

@media (max-width: 580px) {
  .intro-boxes .box {
    margin-bottom: 41px;
  }
  .intro-boxes .box.box4 {
    padding-bottom: 41px !important;
    padding-right: 47px !important;
    height: auto !important;
  }
  .intro-boxes .box.box3 {
    margin-bottom: 41px;
  }
}

@media (max-width: 480px) {
  .intro-boxes .box {
    margin-bottom: 41px;
  }
}

.play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-image: url("../images/ico_play.png");
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  opacity: 1;
  transition: opacity 0.3s;
  cursor: pointer;
}

.preview {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.3s;
  opacity: 1;
  cursor: pointer;
}

.no-margin .quote-image.small {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

h3.colored {
  color: #0a4c98;
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 20px;
}

.carousel-circular {
  padding: 0;
  margin: 0;
  width: calc(100% - 30px);
  display: inline-block;
  padding-bottom: 30px;
}

.carousel-circular .item-circular {
  display: none;
  width: 200px;
  background-size: 100%;
  vertical-align: middle;
  position: relative;
  margin: auto auto;
}

.carousel-circular .item-circular img {
  max-width: 100%;
}

.carousel-circular .item-circular a {
  display: block;
  width: 100%;
}

.carousel-circular .item-circular.transition {
  transition: all 0.35s ease;
}

.carousel-circular .item-circular.active {
  display: block;
}

@media (max-width: 980px) {
  .carousel-circular .item-circular {
    width: calc(100% - 30px);
  }
}

.tabs__more {
  width: 261px;
  margin: 30px auto 10px auto;
  display: block;
}

.main-slider {
  position: relative;
  padding-bottom: 0;
}

.main-slider .slider-numbers {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  margin: 0;
  list-style-type: none !important;
  padding-top: 20px;
}

.main-slider .slider-numbers li {
  text-indent: 0;
  font-size: 13px;
  color: #fff;
  margin-right: 33px;
  font-weight: 400;
  letter-spacing: 1px;
  height: 39px;
  text-align: center;
  position: relative;
  cursor: pointer;
  list-style-type: none !important;
  -webkit-tap-highlight-color: transparent;
}

.main-slider .slider-numbers li:hover, .main-slider .slider-numbers li:focus {
  -webkit-tap-highlight-color: transparent;
}

.main-slider .slider-numbers li.current:before {
  content: '';
  width: 1px;
  height: 21px;
  background: #2ecbea;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.home-slider {
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

@media (max-width: 740px) {
  .home-slider {
    height: 450px;
  }
}

.home-slider video {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (max-width: 740px) {
  .home-slider video {
    display: none;
  }
}

.home-slider .home-slider-image {
  display: block;
}

.home-slider .home-slider-image img {
  max-width: 100%;
}

.home-slider .home-slider-image.show-responsive {
  display: none;
}

@media (max-width: 740px) {
  .home-slider .home-slider-image.show-responsive {
    display: block;
  }
}

@media (max-width: 740px) {
  .home-slider .home-slider-image.hide-responsive {
    display: none;
  }
}

@media (max-width: 740px) {
  .home-slider .home-slider-image {
    height: 450px;
    position: relative;
  }
  .home-slider .home-slider-image img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 450px;
    width: auto;
    max-width: none;
  }
}

.home-slider .home-slider-captions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-weight: bold;
  font-size: 71px;
  display: inline-block;
  left: 70px;
  max-width: 45%;
  line-height: 78px;
  z-index: 2;
}

.home-slider .home-slider-captions div {
  display: none;
}

.home-slider .home-slider-captions button {
  margin-top: 44px;
}

.home-slider .home-slider-captions i {
  font-size: 15px;
  text-transform: uppercase;
  color: #a5a3a3;
  display: block;
  font-style: normal;
  line-height: 0;
  margin-top: 27px;
  font-weight: normal;
  letter-spacing: 2px;
}

@media (max-width: 705px) {
  .home-slider .home-slider-captions {
    max-width: 80%;
    font-size: 41px;
    line-height: 45px;
  }
  .home-slider .home-slider-captions i {
    margin-bottom: 27px;
    margin-top: 27px;
  }
}

@media (max-width: 480px) {
  .home-slider .home-slider-captions {
    left: 30px;
    transform: translate(0, -50%);
  }
  .home-slider .home-slider-captions i {
    display: none;
  }
}

.home-slider .home-slider-dots {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: url("../slider/home-slider-dots.png") no-repeat;
  background-size: 1200px auto;
  z-index: 1;
}

.home-tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.home-tabs__tabs {
  width: 29%;
}

.home-tabs__container {
  width: 71%;
}

.home-tabs .mobile-enabled {
  display: none;
  max-width: 100%;
}

@media (max-width: 820px) {
  .home-tabs .mobile-enabled.showed {
    display: block;
    margin: 20px auto;
  }
}

.home-tabs .tab-link {
  background: none;
  color: #fff;
  display: block;
  padding: 27px 30px 20px 0;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid rgba(232, 237, 243, 0.3);
}

.home-tabs .tab-link:last-of-type {
  border: 0;
}

.home-tabs .tab-link:hover {
  color: #2ecbea;
}

.home-tabs .tab-link.current {
  color: #2ecbea;
}

@media (max-width: 820px) {
  .home-tabs .tab-link.current {
    border-bottom: 0;
  }
}

.home-tabs .tab-link.current:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 2px;
  position: relative;
  top: -3px;
  margin-right: 15px;
  background: #2ecbea;
}

@media (max-width: 820px) {
  .home-tabs .home-tabs__tabs {
    flex-basis: 100%;
  }
  .home-tabs .home-tabs__container {
    display: none;
  }
}

.home-tabs .tab-content {
  display: none;
}

.home-tabs .tab-content img {
  max-width: 100%;
}

.home-tabs .tab-content.current {
  display: block;
  cursor: pointer;
}

@media (max-width: 1170px) {
  .lslide.active .slider-container img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.inline-news-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  top: 20px;
  touch-action: none;
}

@media (max-width: 480px) {
  .inline-news-scroll {
    overflow: hidden;
    touch-action: none;
  }
}

.inline-news-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.inline-news-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.inline-news-scroll-cover {
  overflow: hidden;
  touch-action: none;
}

.inline-news {
  position: relative;
  width: calc(6 * 402px);
  white-space: nowrap;
  margin-left: -40px;
  margin-right: -40px;
}

.inline-news .news-row {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 40px;
  float: left;
  white-space: normal;
  margin-bottom: 30px;
}

.inline-news .news-row .intro {
  font-size: 15px;
  line-height: 23px;
  display: block;
  font-weight: 300;
}

.inline-news .news-row time {
  display: block;
  font-size: 13px;
  margin: 11px 0 22px;
  color: #08478c;
}

.inline-news .news-row b {
  display: block;
  color: #2ecbea;
  font-weight: 500;
  line-height: 26px;
  font-size: 20px;
}

@media (max-width: 800px) {
  .inline-news {
    margin-left: 0;
    margin-right: 0;
  }
  .inline-news .news-row {
    padding: 0;
  }
}

.navbar {
  height: 84px;
  padding-bottom: 0;
  z-index: 100;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin-right: 0;
  box-shadow: 0 4px 5px -3px transparent;
  z-index: 99;
}

.navbar .container {
  width: 1366px;
  display: block;
  box-sizing: border-box;
  position: relative;
  margin: auto;
}

@media (max-width: 1366px) {
  .navbar .container {
    margin-left: 81px;
  }
}

@media (max-width: 980px) {
  .navbar .container {
    padding-left: 31px;
    padding-right: 31px;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .navbar .container {
    width: 100%;
  }
}

.navbar.floatingNav {
  box-shadow: 0 4px 8px -3px rgba(0, 0, 0, 0.15);
}

.inline-news__count {
  position: absolute;
  right: 24px;
  bottom: 0;
  display: flex;
}

.inline-news__count span {
  cursor: pointer;
  margin-left: 33px;
  height: 38px;
  padding: 0;
  position: relative;
  text-indent: 0;
  font-size: 13px;
  background: 0 0;
  width: auto;
  letter-spacing: 1px;
  display: block;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transition: opacity .2s ease;
  font-weight: 400;
}

.inline-news__count span.mobile {
  display: none;
}

.inline-news__count span.current:before {
  content: '';
  position: absolute;
  width: 1px;
  top: 21px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #2ecbea;
}

@media (max-width: 800px) {
  .inline-news__count span.mobile {
    display: inline-block;
  }
}

.list-loading {
  display: block;
  margin-top: 40px;
  transition: opacity 0.2s;
}

.list-loading.loaded {
  opacity: 1;
  transition: opacity 0.2s;
}

.blockhref {
  display: block;
  cursor: pointer;
  text-decoration: none;
}

.tabs {
  list-style-type: none;
  padding: 0;
  clear: both;
  overflow: hidden;
  display: flex;
}

.tabs .tabicon {
  max-width: 25px;
  border: 0;
  margin-top: 8px;
  margin-bottom: 5px;
}

.tabs .tabicon-hovered {
  max-width: 25px;
  border: 0;
  margin-top: 8px;
  margin-bottom: 5px;
}

.tabs a {
  display: block;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  color: #58626e;
  text-align: center;
  user-select: none;
  letter-spacing: 1px;
  padding: 13px 47px 15px;
  box-sizing: border-box;
  float: left;
}

.tabs a .tabicon-hovered {
  display: none;
}

.tabs a svg {
  font-size: 20px;
  margin-bottom: 10px;
}

.tabs a.active {
  background: #fff;
  color: #0a4c98;
}

@media (max-width: 480px) {
  .tabs a.active .tabicon {
    display: none;
  }
  .tabs a.active .tabicon-hovered {
    display: inline-block;
  }
}

@media (max-width: 980px) {
  .tabs a {
    width: calc(100% / 3);
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .tabs a svg {
    display: block;
    float: none;
    margin: 0 auto 10px auto;
  }
}

@media (max-width: 480px) {
  .tabs a {
    font-size: 0;
    background: #fff;
  }
  .tabs a.active {
    background: #08478c;
  }
}

.tabs-container.js .tab-content {
  display: none;
  padding: 40px;
  box-sizing: border-box;
  background: #fff;
  line-height: 23px;
  color: #58626c;
  font-weight: 300;
}

.tabs-container.js .tab-content img {
  max-width: 100%;
}

.tabs-container.js .tab-content.active {
  display: block;
}

.tabs-container.js .tab-content.download-padding {
  padding-bottom: 27px;
}

.tabs-container.js .tab-content .table-scroller {
  max-width: 100%;
  overflow-x: auto;
}

.tab-list {
  list-style-type: none;
  border: 1px solid #dfe3e6;
  vertical-align: top;
  padding-inline-start: 0;
  list-style-type: none !important;
}

.tab-list li {
  padding: 20px 37px;
  font-size: 16px;
  letter-spacing: 1px;
  color: #0a4c98;
  font-weight: 600;
  border-bottom: 1px solid #dfe3e6;
  user-select: none;
  list-style-type: none !important;
}

.tab-list li.iconed svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  font-size: 25px;
}

.tab-list li:last-of-type {
  border-bottom: 0;
}

.half-image {
  width: 100%;
  border: 0;
}

.content-time {
  border: 1px solid #dfe3e6;
  display: inline-block;
  font-size: 13px;
  padding: 11px 32px;
  margin-bottom: 20px;
  user-select: none;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 3px;
}

.inlined {
  display: flex;
}

.inlined p {
  width: 100%;
}

.inlined .step-arrow {
  display: inline-block;
  position: relative;
  top: -50px;
}

.inlined .step-arrow svg.hidden {
  color: transparent !important;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.filelabel {
  appearance: none;
  border-radius: 0;
  border: 1px solid #e8edf3;
  outline: 0;
  height: 50px;
  padding: 0 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}

.filelabel.error {
  border-color: #08478c;
  animation: hideErrorEffect 1s 0.2s;
  animation-fill-mode: forwards;
}

.filelabel .choosed-filename {
  position: relative;
  top: 15px;
  max-width: 90%;
  overflow: hidden;
  display: inline-block;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filelabel svg.uploadicon {
  color: #08478c;
  font-size: 20px;
  vertical-align: middle;
  position: absolute;
  right: 5px;
  top: 14px;
}

.after-info {
  display: none;
  user-select: none;
  color: #0a4c98;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
}

.after-info.active {
  animation: showSendinfo 0.3s;
  animation-fill-mode: forwards;
  display: block;
}

@keyframes showSendinfo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

svg.title-icon {
  color: #2ec4e9;
  display: inline-block;
  margin-right: 5px;
  width: 35px;
}

.formrows {
  display: block;
}

.formrows.description {
  font-size: 14px;
  color: #0a4c98;
}

.formrows .heading {
  color: #0a4c98;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
  display: block;
  margin-top: 41px;
  margin-bottom: 41px;
}

.formrows .heading.small-margin {
  margin-bottom: 20px;
}

.formrows .heading.no-margin-top {
  margin-top: 0;
}

.formrows .formline {
  display: flex;
  margin-top: 11px;
}

.formrows .formline.marginbottom {
  margin-bottom: 35px;
}

@media (max-width: 1255px) {
  .formrows .formline {
    flex-wrap: wrap;
  }
}

.formrows .formline .formbox {
  flex: 1;
  box-sizing: border-box;
  padding: 0 10px;
}

.formrows .formline .formbox .text-input {
  margin-bottom: 0;
}

.formrows .formline .formbox.upload {
  width: 150px;
}

.formrows .formline .formbox.upload:hover svg {
  animation: spinIcon 0.5s;
  animation-fill-mode: forwards;
}

@keyframes spinIcon {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}

@media (max-width: 480px) {
  .formrows .formline .formbox.upload {
    padding-left: 0;
  }
}

.formrows .formline .formbox:first-of-type {
  padding-left: 0;
}

.formrows .formline .formbox:last-of-type {
  padding-right: 0;
}

.formrows .formline .formbox.smallest {
  flex: 0 1 160px;
}

@media (max-width: 480px) {
  .formrows .formline .formbox.smallest {
    flex: 1 0 100%;
    padding-left: 0;
  }
  .formrows .formline .formbox.smallest.mobile-margin {
    margin-top: 20px;
  }
}

.formrows .formline .formbox.smaller {
  flex: 0 1 300px;
}

.formrows .formline .formbox.half {
  flex: 0 1 25%;
}

@media (max-width: 1255px) {
  .formrows .formline .formbox.half {
    flex: 1 0 50%;
  }
}

@media (max-width: 480px) {
  .formrows .formline .formbox.half {
    flex: 1 0 100%;
  }
  .formrows .formline .formbox.half.mobile-margin {
    margin-top: 20px;
  }
}

.formrows .formline .formbox.last {
  flex-grow: 0;
}

@media (max-width: 1255px) {
  .formrows .formline .formbox {
    padding-left: 0;
  }
  .formrows .formline .formbox.mediumpadding {
    padding-top: 22px;
  }
}

@media (max-width: 607px) {
  .formrows .formline .formbox.smallpaddingo {
    padding-top: 22px;
  }
  .formrows .formline .formbox.smallpaddingo2 {
    padding-top: 12px;
  }
}

@media (max-width: 548px) {
  .formrows .formline .formbox.smallpaddingo3 {
    padding-top: 22px;
  }
}

@media (max-width: 480px) {
  .formrows .formline .formbox {
    flex: 1 0 100%;
    padding-left: 0;
  }
}

@media (max-width: 480px) {
  .formrows .formline {
    flex-wrap: wrap;
  }
}

.formrows .formline.no-margin-link .link-formline {
  margin-left: 0;
}

.formrows.bigger-margin .formline {
  margin-top: 21px;
}

.input-select {
  background-size: 100% auto;
  background: url("../images/select-arrow.jpg") calc(100% - 15px) 49% no-repeat;
  width: 100%;
  height: 50px;
}

.inline-radios {
  padding-top: 11px;
}

.inline-radios input {
  width: 0;
  height: 0;
  opacity: 0;
}

.inline-radios i {
  width: 16px;
  height: 16px;
  border: 1px solid #e8edf3;
  display: inline-block;
  margin-right: 2px;
  position: relative;
  top: 4px;
}

.inline-radios label {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
  margin-right: 8px;
  user-select: none;
}

.inline-radios label.error i {
  border-color: #08478c;
  animation: hideErrorEffect 1s 0.2s;
  animation-fill-mode: forwards;
}

.inline-radios label.agree {
  font-size: 14px;
  display: flex;
  font-weight: 300;
}

.inline-radios label.agree ol {
  list-style-type: decimal;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-block-start: 0;
}

.inline-radios label.agree ol li {
  list-style-type: decimal;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-block-start: 0;
}

.inline-radios label.agree i {
  flex: 0 0 16px;
  margin-right: 10px;
  position: relative;
  top: -3px;
}

.inline-radios label.agree a {
  display: inline-block;
}

.inline-radios label.checked i {
  border-color: #0a4c98;
}

.inline-radios label.checked i:before {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  background: #0a4c98;
  bottom: 1px;
}

.inline-radios label:last-of-type {
  margin-right: 0;
}

#cv-form {
  margin-top: 20px;
}

@keyframes hideErrorEffect {
  0% {
    border-color: #08478c;
  }
  40% {
    border-color: #e8edf3;
  }
  60% {
    border-color: #08478c;
  }
  100% {
    border-color: #e8edf3;
  }
}

.lSSlideWrapper {
  border: 0;
}

.main-slider .lSSlideWrapper {
  border: 0;
}

.slider-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
}

.slider-filler {
  opacity: 0;
}

@media (max-width: 680px) {
  .slider-filler {
    min-height: 50vh;
  }
}

.horizontal .lSSlideWrapper {
  border: 0;
}

.lSSlideOuter .lSPager.lSGallery {
  margin: 0;
  padding: 0;
  padding-inline-start: 0;
  margin-block-start: 0;
}

.lSSlideOuter .lSPager.lSGallery li {
  display: inline;
}

.lSSlideOuter.horizontal .lSPager.lSGallery li {
  border: 0;
  max-width: calc(20% - 7.5px);
}

.lightSlider li {
  position: relative;
  list-style-type: none !important;
}

.lightSlider li .caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-weight: bold;
  font-size: 71px;
  display: inline-block;
  left: 70px;
  max-width: 50%;
  line-height: 78px;
  z-index: 2000000;
  backface-visibility: hidden;
}

.lightSlider li .caption button {
  margin-top: 44px;
}

.lightSlider li .caption i {
  font-size: 15px;
  text-transform: uppercase;
  color: #a5a3a3;
  display: block;
  font-style: normal;
  line-height: 0;
  margin-top: 27px;
  font-weight: normal;
  letter-spacing: 2px;
}

@media (max-width: 705px) {
  .lightSlider li .caption {
    max-width: 80%;
    font-size: 45px;
    line-height: 45px;
  }
  .lightSlider li .caption i {
    margin-bottom: 27px;
    margin-top: 27px;
  }
}

@media (max-width: 480px) {
  .lightSlider li .caption {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .lightSlider li .caption i {
    display: none;
  }
}

.lightSlider li img {
  height: auto;
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .lightSlider li img {
    min-height: 450px;
    height: 450px;
    max-width: none;
  }
}

@media (max-width: 480px) {
  .lightSlider li img {
    max-width: none;
  }
}

@media (max-width: 480px) {
  .lightSlider.homepage li img {
    min-height: auto;
    height: 60vh;
  }
}

.lightSlider.homepage li .slider-container img {
  height: 100%;
}

.page-section.same-bottom {
  padding-bottom: 1px;
}

.slider-cover .lightSlider li img {
  min-height: 0;
  height: auto;
  max-width: 100%;
  /*position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);*/
}

.lSSlideOuter {
  /*padding-right: 113px !important;*/
  position: relative;
}

.lSSlideOuter .vertical-controlls {
  appearance: none;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  position: absolute;
  padding: 0;
  margin: 0;
  right: 40px;
}

.lSSlideOuter .vertical-controlls:focus, .lSSlideOuter .vertical-controlls:hover {
  outline: none;
}

.lSSlideOuter .vertical-controlls:hover svg.arrow {
  color: #08478c;
}

.lSSlideOuter .vertical-controlls svg.arrow {
  color: #d2d2d2;
}

.lSSlideOuter .vertical-controlls#prev {
  top: -11px;
}

.lSSlideOuter .vertical-controlls#next {
  bottom: -9px;
}

.lSSlideOuter .lSPager.lSGallery li {
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  height: auto !important;
}

.lSSlideOuter .lSPager.lSGallery li.active {
  border-radius: 0;
}

.lSSlideOuter .lSPager.lSGallery li a img {
  width: 100%;
}

.lSSlideOuter .lSPager.lSGallery li:hover {
  border-radius: 0;
}

.lSSlideOuter.vertical {
  padding-right: 130px !important;
}

.lSSlideOuter.vertical .lSPager {
  transform: none !important;
}

.lSSlideOuter:not(.vertical):not(.horizontal) .lSPager.lSGallery {
  width: 100% !important;
}

.lSSlideOuter:not(.vertical):not(.horizontal) .lSPager.lSGallery li {
  width: 110px !important;
}

.draw-border {
  transition: background 0.2s;
}

@media (hover: hover) {
  .draw-border {
    border: 0 !important;
    box-shadow: inset 0 0 0 1px #2ecbea;
    color: #2ecbea;
    transition: color 0.25s 0.08333s;
    position: relative;
  }
  .draw-border::before, .draw-border::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
  }
  .draw-border::before {
    border-bottom-width: 1px;
    border-left-width: 1px;
  }
  .draw-border::after {
    border-top-width: 1px;
    border-right-width: 1px;
  }
  .draw-border:hover {
    color: #fff;
  }
  .draw-border:hover::before, .draw-border:hover::after {
    border-color: #fff;
    transition: border-color 0s, width 0.25s, height 0.25s;
    width: 100%;
    height: 100%;
  }
  .draw-border:hover::before {
    transition-delay: 0s, 0s, 0.25s;
  }
  .draw-border:hover::after {
    transition-delay: 0s, 0.25s, 0s;
  }
}

.draw-border:hover span:before {
  background-color: #fff;
  transition: background 0.2s;
}

.draw-border-no-background {
  transition: background 0.2s;
}

@media (hover: hover) {
  .draw-border-no-background {
    border: 0 !important;
    box-shadow: inset 0 0 0 1px #fff;
    color: #fff;
    transition: color 0.25s 0.08333s;
    position: relative;
  }
  .draw-border-no-background::before, .draw-border-no-background::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
  }
  .draw-border-no-background::before {
    border-bottom-width: 1px;
    border-left-width: 1px;
  }
  .draw-border-no-background::after {
    border-top-width: 1px;
    border-right-width: 1px;
  }
  .draw-border-no-background:hover {
    color: #0a4c98;
  }
  .draw-border-no-background:hover::before, .draw-border-no-background:hover::after {
    border-color: #0a4c98;
    transition: border-color 0s, width 0.25s, height 0.25s;
    width: 100%;
    height: 100%;
  }
  .draw-border-no-background:hover::before {
    transition-delay: 0s, 0s, 0.25s;
  }
  .draw-border-no-background:hover::after {
    transition-delay: 0s, 0.25s, 0s;
  }
}

.draw-border-no-background span:before {
  transition: background 0.2s;
}

@media (hover: hover) {
  .draw-border-no-background:hover {
    background: transparent;
  }
  .draw-border-no-background:hover span:before {
    background-color: #0a4c98 !important;
    transition: background 0.2s;
  }
}

.pager .lSPager {
  position: absolute;
  bottom: 0;
  right: 40px;
  opacity: 1;
  display: none;
}

.pager .lSPager.loaded {
  opacity: 1;
}

.pager .lSPager.lSpg {
  margin: 0;
}

.pager .lSPager.lSpg li {
  margin-left: 33px;
  height: 45px;
  padding: 0;
  position: relative;
}

.pager .lSPager.lSpg li a {
  text-indent: 0;
  font-size: 13px;
  border-radius: 0;
  background: transparent;
  width: auto;
  height: auto;
  letter-spacing: 1px;
  display: block;
  backface-visibility: visible;
  transition: opacity .2s ease;
  font-weight: normal;
  text-decoration: none;
  color: #fff;
}

.pager .lSPager.lSpg li.active:before {
  content: '';
  position: absolute;
  width: 1px;
  top: 21px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #2ecbea;
}

.pager .lSPager.lSpg li.active a {
  background-color: transparent;
  color: #fff;
}

.pager .lSPager.lSpg li:hover a {
  background-color: transparent;
}

.slider-cover {
  width: 600px;
  box-sizing: border-box;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  margin-top: 30px;
  min-height: 200px;
}

.slick-slider.white-background .slick-dots li button {
  color: #58626c;
}

.slick-slider.white-background .slick-dots li.slick-active button {
  color: #0a4c98;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  min-height: 200px;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media (max-width: 600px) {
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transition: all .5s ease-out;
  }
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-height: 200px;
  transition: all 0.1s;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-track figure {
  margin: 0;
  padding: 0;
  width: 25%;
  position: relative;
}

@media (max-width: 600px) {
  .slick-track figure {
    width: 100%;
  }
}

.slick-track figure img {
  width: 100%;
}

.slick-track figure iframe {
  width: 100%;
  border: 0;
  appearance: none;
}

.slick-slider.border {
  margin-left: -13px;
  margin-right: -10px;
}

.slick-slider.border figure {
  box-sizing: border-box;
  padding: 25px;
  position: relative;
  text-align: center;
  outline: none;
  color: #0a4c98;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin: 10px;
}

.slick-slider.border figure:last-of-type {
  margin-right: 0;
}

@media (hover: hover) {
  .slick-slider.border figure:hover {
    border-color: #7b95c0;
  }
}

.slick-slider.border figure a {
  outline: none;
  text-decoration: none;
}

.slick-slider.border figure label {
  margin-top: 10px;
  outline: none;
}

.slick-slider.news {
  margin-left: -13px;
  margin-right: -10px;
  position: static;
}

@media (max-width: 480px) {
  .slick-slider.news {
    padding-bottom: 30px;
  }
}

.slick-slider.news figure {
  box-sizing: border-box;
  padding: 0;
  position: relative;
  text-align: left;
  outline: none;
  color: #0a4c98;
  font-weight: 600;
  border: 0;
  margin: 10px;
  padding-right: 20px;
}

@media (max-width: 780px) {
  .slick-slider.news figure {
    padding-right: 0;
  }
}

.slick-slider.news figure b {
  display: block;
  color: #2ecbea;
  font-weight: 500;
  line-height: 26px;
  font-size: 20px;
}

.slick-slider.news figure time {
  display: block;
  font-size: 13px;
  margin: 11px 0 22px;
  color: #08478c;
}

.slick-slider.news figure .intro {
  font-size: 15px;
  line-height: 23px;
  display: block;
  font-weight: 300;
}

.slick-slider.news figure .button-box {
  text-decoration: none;
  color: #08478c;
  outline: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  text-transform: uppercase;
  position: relative;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 3px;
  padding: 10px 15px 10px 30px;
  width: 70px;
}

.slick-slider.news figure:last-of-type {
  margin-right: 0;
}

@media (hover: hover) {
  .slick-slider.news figure:hover {
    border-color: #7b95c0;
  }
}

.slick-slider.news figure a {
  outline: none;
  text-decoration: none;
}

.slick-slider.news figure label {
  margin-top: 10px;
  outline: none;
}

.slick-slider.news .slick-dots {
  position: absolute;
  right: 24px;
  bottom: 22px;
  display: flex;
  width: auto;
}

.slick-slider.news .slick-dots li {
  margin-bottom: -2px;
  height: 32px;
}

.slick-slider.news .slick-dots li button {
  cursor: pointer;
  margin-left: 33px;
  height: 38px;
  padding: 0;
  position: relative;
  text-indent: 0;
  font-size: 13px;
  background: 0 0;
  width: auto;
  letter-spacing: 1px;
  display: block;
  transition: opacity .2s ease;
  font-weight: 400;
  color: #58626c;
}

.slick-slider.news .slick-dots li.slick-active button {
  border-bottom: none !important;
}

.slick-slider.news .slick-dots li.slick-active button:before {
  content: '';
  position: absolute;
  width: 1px;
  top: 33px;
  bottom: 0;
  height: 19px;
  left: 50%;
  transform: translateX(-50%);
  background: #2ecbea;
}

@media (max-width: 480px) {
  .slick-slider.news .slick-dots {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .slick-slider.news .slick-dots li:first-of-type button {
    margin-left: 0;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: inline-block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-slide:hover, .slick-slide:focus {
  outline: none;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  margin-top: 30px;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  margin-bottom: 5px;
}

.slick-dots li button {
  font-size: 13px;
  line-height: 0;
  display: block;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: #6b9edd;
  border: 0;
  outline: none;
  background: transparent;
  font-weight: 600;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li.slick-active button {
  border-bottom: 1px solid #2ecbea !important;
  color: #fff;
}

.slick-slider.single .slick-track figure, .slick-slider.border .slick-track figure {
  width: 100%;
}

.slick-slider.single .slick-dots li button, .slick-slider.border .slick-dots li button {
  color: #58626c;
  border-bottom: 1px solid transparent;
}

.slick-slider.single .slick-dots li.slick-active button, .slick-slider.border .slick-dots li.slick-active button {
  color: #0a4c98;
}

.job-desc {
  text-align: center;
  display: block;
  margin-bottom: 50px;
}

.number-list {
  list-style-type: decimal;
}

.number-list li {
  list-style-type: decimal;
}

.hide-background {
  border: 0;
  box-shadow: none;
}

.hide-background:hover {
  box-shadow: inset 0 0 0 1px #fff;
  background: transparent;
  color: #0a4c98;
  border: 1px solid #fff;
}

.hide-background:hover span:before {
  background: #0a4c98;
}

.light-font {
  font-weight: 300;
}

.font-small {
  font-size: 14px;
}

.link-formline {
  color: #0a4c98;
  text-decoration: none;
  margin-left: 10px;
}

.slick-slide:hover .play {
  background-image: url("../images/ico_play_h.png");
}

.show-movie .preview {
  display: none;
}

.show-movie .play {
  display: none;
}

.show-movie iframe {
  display: block;
}

.newsText {
  color: #58626c;
}

a label {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.pointer label {
  cursor: pointer;
}

.infos .inline-grid .row.rowthree {
  padding: 25px;
}

.page-section {
  color: #58626c;
}

.page-section a {
  text-decoration: none;
  color: #0a4c98;
}

.page-section li, .page-section ul {
  list-style-type: disc;
}

.after-infobox {
  margin-top: 30px;
}

.default-icon {
  border: 0;
  max-width: 25px;
  display: inline-block;
  vertical-align: middle;
}

.infowindow {
  font-family: 'Metropolis', sans-serif;
  font-size: 13px;
  color: #94989b;
  font-weight: 300;
  padding: 10px 20px 20px 20px;
  text-align: center;
}

.infowindow a {
  display: block;
  margin-top: 10px;
  color: #0a4c98;
  text-decoration: none;
}

.infowindow a:hover {
  color: #0a4c98;
  text-decoration: none;
}

.infowindow h2 {
  font-size: 13px;
}

@media (min-width: 768px) {
  .slider-mobile-enabled {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .slider-mobile-disabled {
    display: none;
  }
}

button.gm-ui-hover-effect {
  right: 0 !important;
  top: 0 !important;
}

.gm-style .gm-style-iw-t::after {
  display: none !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 0;
  max-height: none !important;
  max-width: none !important;
}

.gm-style-iw-d {
  max-height: none !important;
  max-width: none !important;
}

@media (max-width: 980px) {
  .contact-send {
    margin-top: -30px;
  }
}

.owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

#scrollToTop {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 1250px;
  padding-left: 81px;
  display: none;
}

#scrollToTop.visible {
  display: flex;
  justify-content: flex-end;
}

#scrollToTop a {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: #08478c;
  display: inline-block;
}

#scrollToTop a img {
  width: 100%;
}

@media (max-width: 1350px) {
  #scrollToTop {
    left: 0;
    right: 0;
    width: auto;
  }
}

.download-padding .inline-grid {
  margin-left: 0;
  margin-right: 0;
}
